import classNames from 'classnames';
import { FC, HTMLInputTypeAttribute } from 'react';
import { useFormContext } from 'react-hook-form';
import tw from 'tailwind-styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement | HTMLSelectElement> {
    name: string;
    label?: string;
    description?: string;
    stretch?: boolean;
    type?: HTMLInputTypeAttribute | 'select';
    children?: any;
};

const InputShim = tw.input<Props>`
    rounded-full
    bg-stone-200
    placeholder:text-stone-600
    text-stone-800
    text-lg
    py-3 px-5
    outline-none
    ${( props: Props ) => props.stretch && 'w-full'}
`;

const SelectShim = tw.select`
    rounded-full
    bg-stone-200
    placeholder:text-stone-600
    text-stone-800
    text-lg
    py-3 px-5
    outline-none
    w-full
`;


const Input: FC<Props> = ({ label, description, className, type, children, ...otherProps}) => {
    const { register } = useFormContext();
    
    const defaultValues = {
        stretch: true,
    }
    
    const props = {
        ...otherProps, ...defaultValues
    }
    
    const Field = () => type === 'select' ? <SelectShim { ...register(props.name)} { ...props } >{ children }</SelectShim> : <InputShim type={ type } { ...register(props.name)} { ...props } />
    
    return (
        <label className={ classNames('block', className) }>
            { label && <div className="block text--main text-lg mb-1">{ label }</div>}
            <Field />
            { description && <div className="block text--secondary text-sm mt-2">{ description }</div>}
        </label>
    )
}

export default Input;