import { FC, ReactElement, useCallback } from 'react';
import useAuth from '../../../../states/hooks/useAuth/useAuth';
import useClipboard from '../../../../states/hooks/useClipboard/useClipboard';
import { Button } from '../../../basics';
import { Form, Input } from '../../../elements';

type Props = {
    children?: ReactElement | ReactElement[];
};

const UserProfilePage: FC<Props> = ({ children }) => {
    const { user } = useAuth();
    const { copy } = useClipboard();
    
    const copyToken = useCallback(() => {
        if (user?.token) {
            copy(user?.token);
        }
    }, [ user?.token ]);
    
    return (
        <div className="p-8">
            <div className="mb-6">
                <div className="pre-heading">Hi { user?.user.firstName }</div>
                <div className="heading">Profiel</div>
            </div>
            
            <div className="mb-8">
                Je kan je profiel hier binnenkort beheren.
            </div>
            
            <h3 className="text-lg text--main mb-2">API token</h3>
            <Button onClick={ copyToken } theme="secondary" small fitWidth icon="file-copy" iconPlacement="start">Token kopieren</Button>
            <ul className="text-sm text--secondary mt-3 list">
                <li>Met deze (bearer) token kan je verzoeken sturen naar de API zoals dat de applicatie dat doet</li>
                <li>Een token is 24u geldig vanaf login</li>
                <li>Bij het vernieuwen van de applicatie wordt een nieuwe token aangevraagd maar blijven oude tokens geldig zolang deze niet vervallen zijn</li>
            </ul>
            {/* <p className="break-words">{ user?.token }</p> */}
        </div>
    )
}

export default UserProfilePage;