import classNames from 'classnames';
import { FC, useState } from 'react';
import useAuth from '../../../../../states/hooks/useAuth/useAuth';
import useCart from '../../../../../states/hooks/useCart/useCart';
import usePopover from '../../../../../states/hooks/usePopover/usePopover';
import { Button, CheckoutOverdraftNotify, Icon } from '../../../../basics';
import Pricfy from '../../../../basics/pricify/Pricify';
import { WalletSelectorPopover } from '../../../../elements';

type Props = {
    onCheckout: () => void;
};

const Checkout: FC<Props> = ({ onCheckout }) => {
    // const { flagById } = useFlags()
    // const checkoutOpen = flagById('checkout_overview_visible')?.state;
    const { selectedWallet } = useAuth();
    const [ walletSelectPopoverController, { open: openWalletSelector } ] = usePopover();
    const [ showPriceDetails, setShowPriceDetails ] = useState(false);
    const { total, items, purchaseVerification } = useCart();
    
    const hasWalletSelected = !!selectedWallet;
        
    const handleCheckout = () => {
        if (hasWalletSelected) {
            onCheckout()
        } else {
            openWalletSelector()
        }
    }
    
    // console.log('checkoutOpen', checkoutOpen)

    return (
        <>
            <div className={ classNames(
                'sticky bottom-0 left-0 right-0 bg-white dark:bg-stone-900',
                items.length > 0 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'
            )}>
                <div className="border-t border-gray-300 dark:border-stone-600 px-8 pt-8">
                    <button 
                        onClick={() => setShowPriceDetails(!showPriceDetails)}
                        className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-stone-200 dark:bg-stone-600 rounded-full p-1"
                    >
                        <Icon name="arrow-up-s" className={ classNames('text--main', showPriceDetails && 'rotate-180') } />
                    </button>
                                
                    <div className={ classNames(
                        'overflow-hidden',
                        showPriceDetails ? 'opacity-100 max-h-[100vh] pb-4' : 'opacity-0 max-h-0 pb-0' 
                    )}>
                        <div className="flex justify-between text--main">
                            <span>Subtotaal</span>
                            <span className="font-semibold"><Pricfy>{ total }</Pricfy></span>
                        </div>
                        <p className="text-stone-400 text-sm">Totaal van alle items</p>
                                    
                        {
                            (selectedWallet?.provider.allowOverdraft && selectedWallet?.provider.overdraftFee !== 0) && (
                                <>
                                    <div className="flex justify-between mt-4 text--main">
                                        <span>Boete</span>
                                        <span className="font-semibold">+ 0%</span>
                                    </div>
                                    <p className="text-stone-400 text-sm">Achterstallige betaling</p>
                                </>
                            )
                        }
                                
                        <Button 
                            theme="secondary"
                            icon="arrow-right-s"
                            className="mt-6" 
                            onClick={ openWalletSelector }
                        >{ selectedWallet?.provider?.label || 'Selecteer een wallet'}</Button>
                    </div>
                            
                    <Button 
                        theme="main"
                        disabled={( hasWalletSelected && !purchaseVerification?.purchaseAllowed )}
                        icon="arrow-right" 
                        onClick={ handleCheckout }
                    >
                        <div className="flex items-center justify-between w-full mr-2">
                            <span>Afrekenen</span>
                            <span><Pricfy>{ total }</Pricfy></span>
                        </div>
                    </Button>
                        
                    {( hasWalletSelected && !purchaseVerification?.purchaseAllowed ) && (
                        <CheckoutOverdraftNotify reason={ purchaseVerification?.reason } />
                    )}
                </div>
            </div>
            
            <WalletSelectorPopover { ...walletSelectPopoverController } />
        </>
    )
}

export default Checkout;