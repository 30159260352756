import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { useEffectOnce } from '../../../states/hooks/useEffectOnce/useEffectOnce';

type Props = {
    children?: ReactElement | ReactElement[];
    enabled?: boolean;
};

const defaultMationAngle = 90;
const offset = 0;
const normalYViewAngle = defaultMationAngle + offset;

const Perspective = styled.div<any>`
    perspective: 1000px;
    
    > * {
        width: 100%;
        height: 100%;
        transform:
            translateX(${(props) => props.enabled ? 
                ((props.gamma * -1) / 4) + 'px' : 
                '0px'
            })
            rotateY(
                ${props => props.enabled ? 
                    ((props.gamma * -1) / 6) + 'deg' : 
                    '0deg'}
            )
            /* 
                rotateY(clamp(
                    -15deg,
                    ${props => props.enabled ? 
                        (props.gamma * -1) + 'deg' : 
                        '0deg'}
                    ,
                    15deg
                ))
                rotateX(clamp(
                    -30deg,
                    ${props => props.enabled && ((normalYViewAngle * -1) + (props.beta)) * -1 + 'deg' || '0deg'},
                    30deg
                ))
            */
        ;
        box-shadow: 
            clamp(
                -15px,
                ${props => (props.gamma * -1)}px,
                15px
            )
            clamp(
                -15px,
                ${props => ((props.beta - 90) * -1)}px,
                15px
            )
            30px
            -10px 
            ${props => props.enabled ? '#0000009e' : 'transparent' }
        ;
        transition: all 0.2s ease;
    }
    
    
`;

const ThreeDee: FC<Props> = ({ children, ...otherProps }) => {
    const [dim, setDim] = useState<any>(null);
    
    useEffectOnce(() => {
        window.addEventListener('deviceorientation', event => {
            setDim({
                alpha: event.alpha,
                beta: event.beta,
                gamma: event.gamma
            })
        }, true);
    })
    
    return (
        <Perspective { ...dim } { ...otherProps }>
            { children }
        </Perspective>
    )
}

export default ThreeDee;