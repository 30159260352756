import { FC, useCallback, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useCart from '../../../states/hooks/useCart/useCart';
import useEndPoints from '../../../states/hooks/useEndpoints/useEndpoints';
import usePopover from '../../../states/hooks/usePopover/usePopover';
import usePurchaseVerification from '../../../states/hooks/usePurchaseVerification/usePurchaseVerification';
import { Product } from '../../../types/bar';
import { ID } from '../../../types/general';
import { Wallet } from '../../../types/wallet';
import { Button, CheckoutOverdraftNotify, ExspansionPane, Icon, Pricify } from '../../basics';
import PurchaseProofPopover from '../../pages/productsPages/PurchaseProofPopover';
import Popover from '../popover/Popover';
import ProductCard from '../product/ProductCard';
import WalletSelectorPopover from '../WalletSelectorPopover/WalletSelectorPopover';

interface Props {};

const FastCheckout: FC<Props> = () => {
    const { selectedWallet: selectedWalletFromStore } = useAuth();
    const [ searchParams ] = useSearchParams();
    const [ purchaseProofId, setPurchaseProofId ] = useState<ID | undefined>()
    const { startPurchase, purchase } = useCart();
    const [ selectedWallet, selectWallet ] = useState<Wallet | undefined>(selectedWalletFromStore);
    const [ amount, setAmount ] = useState(parseInt(searchParams.get('amount') || '1'));
    const { productId } = useParams();
    const [ controller, { open: openAmountSelector, close: closeAmountSelector }] = usePopover(true);
    const endpoints = useEndPoints();
    const { data: product } = useAxiosBeta<Product>(endpoints.products.byId(productId as string));
    const [ walletSelectPopoverController, { open: openWalletSelector, close: closeWalletSelector }] = usePopover();
    
    const total = useMemo(() => (
        (product?.price || 0) * amount
    ), [product?.price, amount])
    
    const { result: purchaseVerification } = usePurchaseVerification(total, selectedWallet?.id || '');
    
    const handleItemAdd = () => {
        setAmount(s => s+= 1);
    }
    
    const handleItemRemove = () => {
        setAmount(s => s-= 1);
    }
    
    const handlePurchase = useCallback(async () => {
        if (!!selectedWallet && product) {
            const purchase = await startPurchase({
                items: [{
                    product: product.id,
                    amount
                }],
                wallet: selectedWallet.id
            });
            closeAmountSelector();
            setPurchaseProofId(purchase.data.id);
        } else {
            // closeAmountSelector();
            openWalletSelector();
        }
    }, [selectedWallet, product, amount])
    
    const handleWalletSelect = (wallet: Wallet) => {
        selectWallet(wallet);
        closeWalletSelector();
        openAmountSelector();
    };
    
    const itemNotInCart = useMemo(() => (
        amount === 0
    ), [ amount ]);
    
    return (
        <>
            <Popover { ...controller }>
                <div className="mb-6">
                    <h3 className="popover__title text-center">Snel afrekenen</h3>
                    <h4 className="popover__subtitle text-center">{ product?.name }</h4>
                </div>
                
                { product?.availability === 'unavailable' ? 
                    (<div className="text-center mt-4 text-stone-400">Item niet beschikbaar</div>) :
                    (
                        <div className="flex justify-center items-center gap-6">
                            <button disabled={ amount <= 0 } onClick={ handleItemRemove } className="block border border--themed p-2 w-fit h-fit rounded-full">
                                <Icon name="subtract" size="1.6rem" />
                            </button>
                            <div className="flex flex-col items-center">
                                <span className="text-2xl -mb-1">{  }</span>
                                <span className="text--secondary"><Pricify>{ (product?.price || 0) * amount }</Pricify></span>
                            </div>
                            <button onClick={ handleItemAdd } className="block border border--themed p-2 w-fit h-fit rounded-full">
                                <Icon name="add" size="1.6rem" />
                            </button>
                        </div>
                    )
                }
                
                <ExspansionPane active={ !!selectedWallet }>
                    <p className="text--secondary text-center label text-base mt-6">
                        via { selectedWallet?.provider.label } wallet – <span onClick={() => openWalletSelector()} role="button" className="underline underline-offset-4">wijzigen</span></p>
                </ExspansionPane>
                <ExspansionPane active={ !itemNotInCart }>
                    <div className="mt-3 -mb-1">
                        <Button 
                            onClick={ handlePurchase }
                            loading={ purchase.loading }
                            disabled={ !!selectedWallet && !purchaseVerification?.purchaseAllowed }
                        >{ !!selectedWallet ? 'Direct afrekenen' : 'Wallet selecteren' }</Button>
                        { !purchaseVerification?.purchaseAllowed && <CheckoutOverdraftNotify reason={ purchaseVerification?.reason } /> }
                    </div>
                </ExspansionPane>
            </Popover>
            <WalletSelectorPopover
                { ...walletSelectPopoverController } 
                currentSelected={ selectedWallet?.id }
                onSelect={ handleWalletSelect } 
            />
            <PurchaseProofPopover
                transactionId={ purchaseProofId } 
                onClose={() => setPurchaseProofId(undefined)}
            />
        </>
    )
}

export default FastCheckout;