import { FC } from 'react';
import { useEffectOnce } from '../../../states/hooks/useEffectOnce/useEffectOnce';
import { CartItem } from '../../../types/bar';
import { Pricify } from '../../basics';
import ProductCardBase from '../productCardBase/ProductCardBase';
import ProductAddButton from './ProductAddButton';
import ProductRemoveButton from './ProductRemoveButton';

type Props = {
    product: CartItem;
    clearUnavailable?: boolean;
    onInfoDisplay?: () => void;
    onAddItem?: () => void;
    onRemoveItem?: () => void;
};

const ProductCard: FC<Props> = ({ product, onInfoDisplay, onAddItem, onRemoveItem, clearUnavailable }) => {    
    const handleInfoDisplay = () => {
        if (typeof onInfoDisplay === 'function') {
            onInfoDisplay()
        }
    }
    
    const handleItemAdd = () => {
        if (typeof onAddItem === 'function') {
            onAddItem()
        }
    }
    
    const handleItemRemove = () => {
        if (typeof onRemoveItem === 'function') {
            onRemoveItem()
        }
    }
    
    const handleUnavailibilty = () => {
        if (product.availability === 'unavailable' || product.availability === 'hidden') {
            handleItemRemove()
        }
    }
    
    useEffectOnce(() => {
        if (clearUnavailable) {
            handleUnavailibilty();
        }
    });
    
    return (
        <div 
            key={ product.id }
            className="flex items-center justify-between py-5 border-b border--themed last:border-b-0"
        >
            <ProductCardBase product={ product } onClick={ handleInfoDisplay } />
            { !product.availability || product.availability === 'available' && (
                <div className="flex items-center gap-2">
                    {( onRemoveItem && product.amount > 0 ) && <ProductRemoveButton onClick={ handleItemRemove } />}
                    { onAddItem && <ProductAddButton amount={ product.amount } onClick={ handleItemAdd } />}
                </div>
            )}
        </div>
    )
}

export default ProductCard;