import styled from "styled-components";

interface Props {
    perView: number
    snapStop?: 'normal' | 'always';
}

const Scrollable = styled.div<Props>`
    scroll-snap-stop: ${props => props.snapStop || 'normal' };
    display: inline-block;
    // width: calc((100% / ${props => props.perView}) - ${props => 1 + (1/props.perView)}rem);
    width: calc(100% - 4rem);
    scroll-snap-align: ${props => (props.perView % 2) === 0 ? 'start' : 'center'};
    margin: 0 .5rem;
    // scroll-margin: 8rem;
        
    &:first-child {
        margin-left: 4rem;
    }
        
    &:last-child {
        margin-right: 4rem;
    }
`;

export default Scrollable;