import { FC } from 'react';
import Button from '../button/Button';
import Icon from '../icon/Icon';

interface Props {
    onRetry?: () => void;
    retryLabel?: string;
};

const LoadErrorNotify: FC<Props> = ({ onRetry, retryLabel }) => {
    return (
        <div>
            <div className="flex flex-col items-center mb-4">
                <Icon name="cloud" size="1.6rem" className="mb-2" />
                <h4 className="text-center text--main">Oeps</h4>
                <p className="text-center text--secondary text-sm">Er ging iets mis bij het laden</p>
            </div>
            { !!onRetry && <Button small theme="secondary" onClick={() => onRetry()}> { retryLabel ? retryLabel : 'Probeer opnieuw' }</Button> }
        </div>
    )
}

export default LoadErrorNotify;