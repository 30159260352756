import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../../states/hooks/useApp/useApp';
import { Button } from '../../basics';
import { Loader } from '../../elements';

type Props = {
    children?: ReactElement | ReactElement[];
};

const UpdatePage: FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const { updateAvailable, updateInfo } = useApp();
    
    const updateByClearingCache = () => {
        caches.keys().then(cacheNames => {
            console.log('caches', cacheNames)
            cacheNames.forEach(cacheName => {
              caches.delete(cacheName);
            });
            console.log('These caches are cleared');
        });
        window.location.assign('/app?notified=updated');
    }
    
    return (
        <div className="pt-8 h-full flex flex-col">
            <div className="flex-1">
                <div className="px-8 pb-6 border-b border--themed">
                    <div className="mb-6">
                        <h2 className="pre-heading">Applicatie</h2>
                        <h3 className="heading">Update</h3>
                    </div>
                    { updateAvailable ? 
                        <h4 className="text-lg text--main">Update beschikbaar</h4> :
                        <h4 className="text-lg text--main">BarApp is up-to-date</h4>
                    }
                </div>
                
                { updateAvailable && (
                    <div className="px-8 py-6 bg-stone-100 dark:bg-stone-800">
                        <h4 className="text-lg text--main mb-1">Over deze update</h4>
                        { updateInfo ? 
                            <>
                                <p className="text--secondary whitespace-pre-line" dangerouslySetInnerHTML={{ __html: updateInfo?.commit?.message }} />
                                <Button 
                                    onClick={ updateByClearingCache } 
                                    theme="simple" small fitWidth 
                                    className="mt-6 mx-auto" 
                                    icon="download" iconPlacement="start"
                                >Installeren</Button>
                            </> :
                            <div className="mt-4">
                                <Loader />
                            </div>
                        }
                    </div>
                )}
                
            </div>
            
            <Button theme="secondary" small fitWidth className="mx-auto">Update zoeken</Button>
        </div>
    )
}

export default UpdatePage;