import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AnimatedList, Button, Icon } from '../../basics';
import { Popover, WalletPreviewScrollList } from '../../elements';
import useQrScanPopover from '../../../states/hooks/useQrScanPopover/useQrScanPopover';
import useFlags from '../../../states/hooks/useFlags/useFlags';
import useCart from '../../../states/hooks/useCart/useCart';
import useLocationSearch from '../../../states/hooks/useLocationSearch/useLocationSearch';
import usePopover from '../../../states/hooks/usePopover/usePopover';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import { predefinedCheckouts } from '../../../utils/data/fastcheckout';

type Props = {
    children?: any;
};

const UpdateNotify = () => {
    const { search } = useLocationSearch();
    const [ controller ] = usePopover(!!search?.notified);
    
    return (
        <Popover { ...controller }>
            <div className="mb-6">
                <h3 className="popover__title">Update</h3>
                <h4 className="popover__subtitle">Helemaal up-to-date</h4>
            </div>
            <div className="mb-6">
                <p className="text--secondary">Je kan nu de nieuwste versie gebruiken.</p>
            </div>
            <Link to="/info/update">
                <Button
                    theme="secondary"
                    small fitWidth
                    icon="arrow-right"
                    className="mx-auto"
                >Updates controleren</Button>
            </Link>
        </Popover>
    )
}

const ContinueOrder = () => {
    const { total } = useCart();
    
    if (total === 0) return null;
    else return (
        <Link to="/bar" className="block">
            <div className="flex items-center px-8 pb-4 mb-6 border-b border--themed">
                <div>
                    <h3 className="text-lg text--main">Klaar met bestellen?</h3>
                    <p className="text-sm text--secondary">Er zitten nog enkele dingen in je winkelmandje, verdergaan?</p>
                </div>
                <div className="flex-1"> 
                    <Icon name="arrow-right" size="1.6rem" className="text--secondary" />
                </div>
            </div>
        </Link>
    )
}

const StartPage: FC<Props> = ({ children }) => {
    const { user } = useAuth();
    const { open } = useQrScanPopover();
    const { flagById } = useFlags();

    const showQRScanner = flagById('qr_scanner_enabled')?.state;
     
    return (
        <>
            <UpdateNotify />
            <div className="flex flex-col h-full">
                <div className="flex-1">
                    <div className="px-8 pt-8 mb-6">
                        <div className="pre-heading">Hi { user?.user.firstName }</div>
                        <div className="heading">Overzicht</div>
                    </div>
                    <ContinueOrder />
                    <div className="mb-8 px-8">
                        <h3 className="text-lg text--main mb-3">Snel bestellen</h3>
                        <AnimatedList className="flex gap-4">
                            { predefinedCheckouts.map((item) => (
                                <li className="flex-1">
                                    <Link
                                        to={ `bar/drivethru/${ item.product }?amount=${ item.amount }` }
                                        className="block bg-stone-100 p-3 rounded-lg"
                                    >
                                        <h3 className="text--main text-lg -mb-1">{ item.descriptor }</h3>
                                        <p className="text--secondary text-sm">{ item.label }</p>
                                    </Link>
                                </li>
                            ))}
                        </AnimatedList>
                    </div>
                    <div className="mb-8">
                        <div className="flex justify-between items-baseline mb-2 px-8">
                            <h3 className="text-lg text--main">Wallets</h3>
                            <Link to="user/wallets" className="label text-stone-500 flex items-center gap-1">
                                Bekijk wallets
                                <Icon name="arrow-right" size="1rem" />
                            </Link>
                        </div>
                        <WalletPreviewScrollList showInitalizePopover transactionsLimit={ 3 } />
                    </div>
                </div>
                { showQRScanner && (
                    <div className="sticky bottom-0 left-0 right-0 flex justify-center">
                        <Button 
                            small 
                            fitWidth 
                            theme="secondary" 
                            icon="qr-code"
                            onClick={ open }
                        >Code scannen</Button>
                    </div>
                )}
            </div>
        </>
    )
}

export default StartPage;