import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { Product } from '../../../../types/bar';
import { AnimatedList, Button } from '../../../basics';
import ProductCardBase from '../../../elements/productCardBase/ProductCardBase';

type Props = {
    children?: ReactElement | ReactElement[];
};

const ProductManageIndexPage: FC<Props> = ({ children }) => {
    const endpoints = useEndPoints();
    const { user } = useAuth();
    const { data: products } = useAxiosBeta<Product[]>(endpoints.products.all);
    
    return (
        <div className="px-8 pt-8">
            <div className="mb-6">
                <h2 className="pre-heading">Beheer</h2>
                <h3 className="heading">Items</h3>
            </div>
            
            <AnimatedList>
                {
                    products?.map((product) => (
                        <li 
                            key={ product.id }
                            className="border-b border--themed last:border-b-0"
                        >
                            <Link 
                                to={ product.id }
                                className="block py-5"
                            >
                                <ProductCardBase product={ product } />
                            </Link>
                        </li>
                    ))
                }
            </AnimatedList>
            <Link to="new" className="block sticky bottom-0">
                <Button>Item aanmaken</Button>
            </Link>
        </div>
    )
}

export default ProductManageIndexPage;