import { FC, ReactElement, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useLazyAxios from '../../../../states/hooks/useAxiosBeta/useLazyAxios';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { Product } from '../../../../types/bar';
import { Button, Toggle } from '../../../basics';
import { Form, Input } from '../../../elements';

type Props = {
    children?: ReactElement | ReactElement[];
};

const ProductEditPage: FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const endpoints = useEndPoints();
    const { data: product } = useAxiosBeta<Product>(endpoints.products.byId(id as string))
    const [ saveProduct, { data: savedProduct, loading: loadingSavedProduct }] = useLazyAxios(endpoints.products.byId(id as string), {
        method: 'PATCH',
    });
    
    const handleProductSave = useCallback(async (product: Product) => {
        await saveProduct(product);
        navigate('..');
    }, [saveProduct]);
    
    if (!product) return null;
    else return (
        <div className="px-8 pt-8 h-full flex flex-col">
            <div className="mb-6">
                <h2 className="pre-heading">Items</h2>
                <h3 className="heading">Bewerk { product.name }</h3>
            </div>
            <Form 
                className="flex-1 flex flex-col" 
                defaultValues={{
                    name: product.name,
                    price: product.price,
                    quantity: product.quantity,
                    availability: product.availability,
                }}
                onSubmit={ handleProductSave }
            >
                <div className="flex-1">
                    <Input
                        label="Beschikbaarheid"
                        name="availability"
                        required
                        className="mb-4"
                        type="select"
                    >
                        <option value="available">Beschikbaar</option>
                        <option value="unavailable">Onbeschikbaar</option>
                        <option value="hidden">Verbergen</option>
                    </Input>
                    {/* <p className="text--secondary text-sm">Het product wordt al dan niet verborgen uit de lijst.</p>
                    <p className="text--secondary text-sm">Het product blijft zichtbaar, maar kan niet besteld worden.</p> */}
                    <Input
                        label="Naam"
                        name="name"
                        required
                        className="mb-4"
                    />
                    <Input 
                        label="Prijs"
                        type="number"
                        name="price"
                        min="0"
                        step="0.01"
                        required
                        inputMode="decimal"
                        className="mb-4"
                    />
                    <Input 
                        label="Hoeveelheid"
                        description="Beschrijf de hoeveelheid. Bv. blikje, beker, 33cl, ..."
                        name="quantity"
                        min="0"
                        className="mb-4"
                    />
                    {/* <Input 
                        label="Hoeveelheid"
                        description="Beschrijf de hoeveelheid. Bv. blikje, beker, 33cl, ..."
                        name="quantity"
                        className="mb-4"
                        type="select"
                    >
                        <option>Shot</option>
                        <option defaultChecked>Beker (25cl)</option>
                        <option>Blikje (33cl)</option>
                    </Input> */}
                </div>
                <p className="text-sm text--secondary text-center mb-4">Je kan een product niet verwijderen. Verberg het product om bestellingen te voorkomen.</p>
                <Button type="submit" loading={ loadingSavedProduct }>Opslaan</Button>
            </Form>
        </div>
    )
}

export default ProductEditPage;