export const predefinedCheckouts = [
    {
        product: '62a5f9bc6cb918e5493be361',
        descriptor: 'flesje',
        label: 'Cara Pils',
        amount: 1
    },
    {
        product: '6387aa2bcd8050177669853f',
        descriptor: 'bak',
        label: 'Cara Pils',
        amount: 1
    }
]