import { FC, useState } from "react";
import useEndPoints from "../../../states/hooks/useEndpoints/useEndpoints";
import { AnimatedList, Button, ExspansionPane, Icon } from "../../basics";
import Form from "../form/Form";
import Input from "../input/Input";
import Loader from "../loader/Loader";
import Popover, { PopoverProps } from "../popover/Popover";
import useLazyAxios from "../../../states/hooks/useAxiosBeta/useLazyAxios";
import classNames from "classnames";

type PhotoSelectorPopoverProps = PopoverProps & {
    onSelect?: (photo: any) => void;
}

const PhotoSelectorPopover: FC<PhotoSelectorPopoverProps> = ({ onSelect, ...props }) => {
    const [ selected, setSelected ] = useState<string | null>(null);
    const endpoints = useEndPoints();
    const [search, { loading, data }] = useLazyAxios<any>('');
    
    const handleSearch = (data: any) => {
        setSelected(null);
        search(null, endpoints.unsplash.searchByQuery(data.query))
    }
    
    return (
        <Popover { ...props }>
            <div className="mb-4">
                <h3 className="popover__title">Cover afbeelding</h3>
                <h4 className="popover__subtitle">Zoek een afbeelding</h4>
            </div>
            
            <Form onSubmit={ handleSearch } className="sticky top-0 z-20">
                <Input name="query" type="search" placeholder="Zoekterm" />
            </Form>
            
            <div className="mt-6">
                { loading && <Loader />}
                <AnimatedList>
                    { data?.results.map((photo: any, index: number) => {
                        const isSelected = selected === photo.id
                        
                        return (
                            <li key={ index } className="relative rounded-xl overflow-hidden mb-4" onClick={() => setSelected(s => s === photo.id ? null : photo.id)}>
                                <img src={ photo.urls.regular } alt="" className={classNames(
                                    isSelected ? 'blur-sm' : 'blur-0',
                                    'ratio-card w-full object-cover'
                                )} />
                                { selected === photo.id && (
                                    <div className="absolute inset-0 bg-black bg-opacity-50 z-10 flex items-center justify-center">
                                        <Icon name="check" className="text-stone-200" size="2.5rem" />
                                    </div>
                                )}
                            </li>
                        )
                    })}
                </AnimatedList>
            </div>
            <div className="sticky bottom-0">
                <ExspansionPane active={ !!selected }>
                    <Button
                        onClick={() => onSelect?.(selected)}
                        className={classNames(
                            'mt-6',
                        )}
                    >Afbeelding selecteren</Button>
                </ExspansionPane>
            </div>
        </Popover>
    )
}

export default PhotoSelectorPopover;