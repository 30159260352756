import { FC, ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useLazyAxios from '../../../../states/hooks/useAxiosBeta/useLazyAxios';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import useSessionStore from '../../../../states/stores/useSessionStore';
import { Pin, User, UserBasics, UserRegistration } from '../../../../types/verification';
import { Button } from '../../../basics';
import { CodePad, Form, Input, Popover } from '../../../elements';
import Codepad from '../../../elements/codePad/CodePad';

type Props = {
};

const UserRegisterPage: FC<Props> = () => {
    const [ showCodePad, setShowCodePad ] = useState(false);
    const [ userData, setUserData ] = useState<UserBasics>()
    const { registerSession } = useSessionStore();
    const navigate = useNavigate();
    const endpoints = useEndPoints();
    const [ registerUser, { data, loading, error }] = useLazyAxios<User>(endpoints.auth.register, {
        method: 'POST',
    });
    
    const handleSubmit = (data: UserBasics) => {
        setUserData(data);
        setShowCodePad(true);
    }
    
    const handleRegistration = async (values: UserRegistration) => {
        try {
            const registeredUser = await registerUser(values);
            if (registeredUser) {
                const sessionId = registerSession(registeredUser);
                navigate('/session/' + sessionId);
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const handlePinEnter = (pin: Pin) => {
        handleRegistration({
            ...userData as UserBasics,
            pin: pin.join('')
        })
    }
    
    return (
        <>
            <div className="p-8 h-full flex flex-col">
                <div className="mb-6">
                    <h3 className="pre-heading">Gebruiker</h3>
                    <h4 className="heading">Registreren</h4>
                </div>
                <Form 
                    onSubmit={ handleSubmit }
                    className="flex-1 flex flex-col"
                >
                    <div className="flex-1">
                        <Input
                            name="firstName"
                            label="Voornaam"
                            required
                            className="mb-4"
                            placeholder="Robert"
                        />
                        <Input
                            name="lastName"
                            label="Achternaam"
                            required
                            className="mb-4"
                            placeholder="Baden-Powell"
                        />
                        <Input
                            name="email"
                            label="Email"
                            required
                            type="email"
                            placeholder="robert1857@gmail.com"
                        />
                    </div>
                    <Button type="submit" loading={ loading }>Registreren</Button>
                </Form>
                <Link to="../" className="mt-6">
                    <Button theme="simple" type="submit" disabled={ loading }>Terug</Button>
                </Link>
            </div>
            <Popover active={ showCodePad } onClose={() => setShowCodePad(false)}>
                <div className="mb-6">
                    <h3 className="popover__title text-center">Kies een pincode</h3>
                    <h4 className="popover__subtitle text-center">Gebruik om aan te melden</h4>
                </div>
                <Codepad onComplete={ handlePinEnter } />
            </Popover>
        </>
    )
}

export default UserRegisterPage;