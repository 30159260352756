import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useCart from '../../../../../states/hooks/useCart/useCart';
import { Product } from '../../../../../types/bar';
import { Icon } from '../../../../basics';
import Pricfy from '../../../../basics/pricify/Pricify';
import { Popover, Shielded } from '../../../../elements';

type Props = {
    selectedProduct: Product;
    onClose: () => void;
}

const ProductDetailPopup: FC<Props> = ({ selectedProduct, onClose }) => {
    const { getItem, addItem, removeSingle } = useCart();
    
    const cartitem = getItem(selectedProduct);
    const itemNotInCart = cartitem.amount === 0;
    
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    }
    
    const isEditor = true;
    
    return (
        <Popover active={ !!selectedProduct } onClose={ handleClose }>
            { selectedProduct && (
                <>
                    <div className={classNames('flex items-center', isEditor ? 'justify-between' : 'justify-center')}>
                        <div>
                            <h3 className="popover__title">{ selectedProduct.name }</h3>
                            <h4 className="popover__subtitle"><Pricfy>{ selectedProduct.price }</Pricfy></h4>
                        </div>
                        <Shielded>
                            <Link to={ '/manage/products/' + selectedProduct.id } className="w-10 h-10 border border-black dark:border-stone-200 rounded-full flex items-center justify-center">
                                <Icon name="pencil" className="text--main" />
                            </Link>
                        </Shielded>
                    </div>
                    
                    { selectedProduct.availability === 'unavailable' && <div className="text-center mt-4 text-stone-400">Item niet beschikbaar</div>}
                        
                    {( !selectedProduct.availability || selectedProduct.availability === 'available') && (
                        <>
                            <div className={ classNames('flex mt-8') }>
                                <div className={ classNames('overflow-hidden', itemNotInCart ? 'opacity-0 max-w-[0vw] pr-0' : 'opacity-100 max-w-[100vw] pr-4')}>
                                    <button 
                                        disabled={ itemNotInCart }
                                        onClick={() => removeSingle(selectedProduct)}
                                        className="rounded-full p-2 bg-stone-200 dark:bg-stone-400 w-12 h-12 flex items-center justify-center"
                                    >
                                        <Icon name="subtract" size="1.6rem" />
                                    </button>
                                </div>
                                <button 
                                    onClick={() => addItem(selectedProduct)}
                                    className="flex-1 flex justify-between bg-black dark:bg-stone-200 text-white dark:text-black rounded-full py-3 pl-4 pr-2"
                                >
                                    <span className="font-medium">{ cartitem.amount || 0 } items</span>
                                    <span className="flex items-center">
                                        (<Pricfy>{( selectedProduct.price || 0) * (cartitem.amount || 0)}</Pricfy>)
                                        <Icon name="add" size="1.6rem" className="ml-1" />
                                    </span>
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}
        </Popover>
    )
}

export default ProductDetailPopup;