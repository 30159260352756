import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../../basics';

type Props = {
    children?: ReactElement | ReactElement[];
};

const ManagePage: FC<Props> = ({ children }) => {    
    const menuItems = [
        {
            label: 'Gebruikers',
            icon: 'account-circle',
            route: '/manage/users',
        },
        {
            label: 'Items',
            icon: 'goblet',
            route: '/manage/products',
        },
        {
            label: 'Credit providers',
            icon: 'wallet',
            route: '/manage/credit-providers',
        },
    ]
    
    return (
        <div className="p-8">
            <div className="mb-6">
                <div className="pre-heading">Beheer</div>
                <div className="heading">Overzicht</div>
            </div>

            { menuItems.map(({ label, icon, route }) => (
                <div className="w-full border-b border-stone-300 dark:border-stone-700 flex items-center py-4">
                    <Icon name={ icon } size="1.6rem" className="mr-4 text--main" />
                    <div className="w-full">
                        <Link to={ route } className="w-full">
                            <span className="block text-lg leading-5 text--main">{ label }</span>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ManagePage;