import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { User } from '../../../../types/verification';
import { AnimatedList, LoadErrorNotify } from '../../../basics';
import { Loader, UserListItem } from '../../../elements';

type Props = {
    children?: ReactElement | ReactElement[];
};

const UserOverviewManagePage: FC<Props> = ({ children }) => {
    const endpoints = useEndPoints();
    const { user } = useAuth();
    const { data: users, loading: loadingUsers, error: usersFetchError, refetch: refetchUsers } = useAxiosBeta<User[]>(endpoints.user.all);
    
    return (
        <div className="p-8 flex flex-col h-full">
            <div className="mb-6">
                <div className="pre-heading">Beheer</div>
                <div className="heading">Gebruikers</div>
            </div>
            
            { usersFetchError ? (
                <div className="flex-1 flex items-center justify-center">
                    <LoadErrorNotify onRetry={ refetchUsers }  />
                </div>
            ) : 
            <div className="flex-1">
                { loadingUsers && <Loader /> }
                <ul>
                    
                    <AnimatedList>
                        { users?.map((user) => (
                            <li key={user.id}>
                                <Link to={ user.id}>
                                    <UserListItem user={ user } />
                                </Link>
                            </li>
                        ))}
                    </AnimatedList>
                </ul>
            </div>
            }
        </div>
    )
}

export default UserOverviewManagePage;