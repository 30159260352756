import { FC, useState } from 'react';
import { ID } from '../../../types/general';
import useFlags from '../../../states/hooks/useFlags/useFlags';
import { Icon, Toggle } from '../../basics';
import classNames from 'classnames';
import { Popover } from '../../elements';
import defaultFlags from '../../../utils/data/flags';

interface Props {
    flagId: ID;
};

const FlagListCard: FC<Props> = ({ flagId }) => {
    const [showDetails, setShowDetails] = useState(false);
    const storedState = useFlags((store) => store.betaFlags)
    const toggleFlag = useFlags((store) => (state: boolean) => store.toggleFlag(flagId, state))
        
    const flag = defaultFlags.find(({ id }) => id === flagId);
    
    if (!flag) return null;
        
    const state = storedState[flagId] || flag.state;
            
    return (
        <>
            <div 
                className="list__item flex items-center gap-4"
                onClick={() => setShowDetails(true)}
            >
                <div className="w-full flex items-center">
                    <div className="flex-1 flex items-center justify-between mr-6">
                        <h4 className="text-lg text--main">{ flag.label }</h4>
                        { flag.beta && (<span className="label text-sm text--secondary">Beta</span>)}
                    </div>
                    <Icon 
                        name={ state ? 'checkbox-circle' : 'checkbox-blank-circle'} 
                        className={classNames('text--main mr-4', state ? 'opacity-100' : 'opacity-20')}
                    />
                </div>
            </div>
            
            <Popover active={ showDetails } onClose={() => setShowDetails(false)}>
                <div className="mb-4">
                    <h3 className="popover__title mb-2">{ flag.label }</h3>
                    <p className="text-stone-500 dark:text-stone-400 text-sm">{ flag.description }</p>
                </div>
                <label className="flex items-center gap-4">
                    <Toggle 
                        onChange={(event) => {
                            toggleFlag(!event)
                        }}
                        defaultChecked={ state } 
                        name={ flag.id }
                    />
                    <span className="text--main">{ state ? 'Ingeschakeld' : 'Uitgeschakeld' }</span>
                </label>
            </Popover>
        </>
    )
}

export default FlagListCard;