import { FC, ReactElement } from 'react';
import { Icon } from '../../basics';

type Props = {
    size?: string;
};

const Loader: FC<Props> = ({ size }) => {
    return (
        <div className="animate-spin w-fit mx-auto">
            <Icon name="loader-5" size={ size || '3rem' } className="text--main" />
        </div>
    )
}

export default Loader;