import { FC } from 'react';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import { ID } from '../../../types/general';
import { Wallet } from '../../../types/wallet';
import Popover from '../popover/Popover';
import WalletSelector from '../WalletSelector/WalletSelector';

type Props = {
    children?: any;
    active: boolean;
    currentSelected?: ID;
    onClose: () => void;
    onSelect?: (wallet: Wallet) => void;
};

const WalletSelectorPopover: FC<Props> = ({ active, onClose, onSelect, currentSelected }) => {
    const { user, selectedWallet, selectWallet } = useAuth();
    
    const handleWalletSelect = (wallet: ID) => {
        selectWallet(wallet);
        
        if(!!onSelect) {
            onSelect(user?.wallets.find(w => w.id === wallet) as Wallet);
        }
    }
    
    return (
        <Popover {...{ active, onClose }}>
            <div className="mb-2">
                <h3 className="popover__title">Wallet</h3>
                <h4 className="popover__subtitle">Wallet voor deze aankoop</h4>
            </div>
            <WalletSelector
                onSelect={ handleWalletSelect }
                showCheck={ !onSelect || !!currentSelected }
                currentSelected={ currentSelected || selectedWallet?.id }
            />
            {
                !onSelect && (
                    <div className="p-4 bg-stone-100 text--secondary rounded-lg text-sm">
                        Deze voorkeur blijft behouden<br/>bij volgende aankopen
                    </div>
                )
            }
        </Popover>
    )
}

export default WalletSelectorPopover;