import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Icon } from '../../components/basics';
import { RouterOutlet } from '../../components/elements';
import { OverviewPage, UsersOverviewPage } from './pages';
import RolesOverviewPage from './pages/RolesPages/RolesOverviewPage';
import UserManagePage from './pages/UsersPages/UserManagePage';

type Props = {
};

const BackOffice: FC<Props> = () => {
    
    return (
        <div className="h-screen flex flex-col">
            <header className="px-8 py-4 flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <img src="/logoBeta521.png" className="h-16" />
                    <h1 className="text-lg font-semibold text--main">BarApp / backoffice</h1>
                </div>
                <div className="p-4 bg-stone-100 rounded-full w-fit">
                    <Icon name="user-unfollow" />
                </div>
            </header>
            <div className="flex-1">
                <Routes>
                    <Route path="/" element={ <OverviewPage /> } />
                    <Route path="users" element={ <RouterOutlet /> }>
                        <Route index element={ <UsersOverviewPage /> } />
                        <Route path=":id" element={ <UserManagePage /> } />
                    </Route>
                    <Route path="roles">
                        <Route index element={<RolesOverviewPage />} />
                        <Route path=":id" element={<RolesOverviewPage />} />
                    </Route>
                </Routes>
            </div>
            <footer className="bg-stone-50 border-t-[1px] border-stone-200 px-8 py-1 flex justify-between">
                <h1 className="text-xs label text-stone-400">Barapp Backoffice 1.0</h1>
                <div className="text-xs label text-stone-400">Ingelogd als <span className="text-stone-500">Beheerder</span></div>
            </footer>
        </div>
    )
}

export default BackOffice;