import { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import useCart from '../../../states/hooks/useCart/useCart';
import { Icon } from '../../basics';
import Shielded from '../shielded/Shielded';

type StickyFooterProps = {
    children?: any;
}

type Props = {
};

const ActiveLink = styled(NavLink)`
    padding: .75rem;
    border-bottom: 2px solid transparent;

    &.active {
        border-bottom: 2px solid #00bcd4;
    }
`;

const FooterWrapper = styled.footer`
    // box-shadow: 0px 1px 0px rgba(17,17,26,0.05), 0px 0px 8px rgba(17,17,26,0.1);
`;

const StickyFooter: FC<StickyFooterProps> = ({ children }) => {
    return (<div className="sticky bottom-0 left-0 right-0 z-40 p-8 flex justify-center">
        { children }
    </div>)
}

const Footer: FC<Props> = () => {
    const { user } = useAuth();
    
    const menuItems = useMemo(() => [
        { to: '/', icon: 'home-5', show: true },
        { to: '/statistics', icon: 'bar-chart-grouped', show: true },
        { to: '/bar', icon: 'goblet', show: true },
        { to: '/user', icon: 'account-circle', show: true },
        { to: '/manage', icon: 'shield-user', show: user?.user.role.id === '62f2d12c528c0cb30e837b74' },
    ], [user?.user.role.id]);
    
    const itemsToShow = menuItems.filter((item) => item.show);
    
    return (
        <FooterWrapper className="w-fit">
            <div className="w-fit">
                <div className="px-3"> 
                    <div className="flex justify-evenly gap-4">
                        { itemsToShow.map((item) => (
                            <ActiveLink to={ item.to }>
                                {({ isActive }) => (
                                    <Icon className={ isActive ? 'text-[#00bcd4] -translate-y-0.5' : 'text-stone-600' } name={ item.icon } size="1.6rem" />
                                )}
                            </ActiveLink>
                        ))}
                    </div>
                </div>
            </div>
        </FooterWrapper>
    )
}

export { StickyFooter }
export default Footer;