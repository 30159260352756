import axios from 'axios';
import classNames from 'classnames';
import { FC, ReactElement, useState } from 'react';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useEndPoints from '../../../states/hooks/useEndpoints/useEndpoints';
import { User } from '../../../types/verification';
import { Wallet } from '../../../types/wallet';
import { Button, Icon } from '../../basics';
import Loader from '../loader/Loader';
import Popover, { PopoverProps } from '../popover/Popover';
import UserListItem from '../userListItem/UserListItem';

type ContentProps = {
    currentAmount: number;
    selectedSuggestedAmount: number | undefined;
    user: User;
    setSuggestedAmount: (amount: number) => void;
    incrementAmount: (amount: number) => void;
    decrementAmount: (amount: number) => void;
    confirmTopup: () => void;
}

const Content: FC<ContentProps> = ({ currentAmount, user, selectedSuggestedAmount, setSuggestedAmount, incrementAmount, decrementAmount, confirmTopup }) => {
    const suggestedAmounts = [ 3, 5, 10, 15, 20 ];
    
    return (<>
        {/* <div className="mb-5">
            <h3 className="popover__title">Topup</h3>
            <h4 className="popover__subtitle">Krediet toevoegen of aftrekken</h4>
        </div> */}
            
        <div className="mb-10">
            {/* <hr /> */}
            <div className="mb-5">
                <UserListItem user={ user } single />
            </div>
            <hr />
        </div>
        <div className="flex justify-center items-center mb-4">
            <button 
                onClick={() => decrementAmount(1)}
                className="w-10 h-10 flex items-center justify-center bg-stone-200 rounded-full p-1"
            >
                <Icon name="subtract" />
            </button>
            <div className="flex flex-col items-center mx-6">
                <span className={ classNames('text-6xl text--main', currentAmount === 0 && 'opacity-50')}>{ currentAmount }</span>
                <span className="-mt-1 text--main">euro</span>
            </div>
            <button 
                onClick={() => incrementAmount(1)}
                className="w-10 h-10 flex items-center justify-center bg-stone-200 rounded-full p-1"
            >
                <Icon name="add" />
            </button>
        </div>
        <ul className="overflow-x-scroll overflow-y-hidden whitespace-nowrap scrollbar-hide -mx-8 mb-10">
            {
                suggestedAmounts.map((suggestion, index) => (
                    <li
                        onClick={() => setSuggestedAmount(suggestion)}
                        key={ index }
                        className={classNames(
                            ( selectedSuggestedAmount && ( suggestion === currentAmount )) && 'bg-stone-200 dark:bg-stone-700',
                            'inline-block border border-stone-200 dark:border-stone-700 px-3 py-1 rounded-full mx-1 first:ml-8 last:mr-8 text--main'
                        )}
                    >{ suggestion } euro</li>
                ))
            }
        </ul>
        <Button onClick={ confirmTopup }>Bevestig</Button>
    </>)
}

type Props = {
    active: boolean;
    wallet: Wallet;
    onSuccess?: () => void;
}  & PopoverProps;

const TopupPopover: FC<Props> = ({ active, wallet, onSuccess, onClose, ...otherProps }) => {
    const [ topupState, setTopupState] = useState<'ilde' | 'loading' | 'success' | 'error'>('ilde');
    const [ selectedSuggestedAmount, setSelectedSuggestedAmount ] = useState<number | undefined>(undefined);
    const [ amount, setAmount ] = useState(0);
    const endpoints = useEndPoints();
    const { user: authUser } = useAuth();
    const { data: userData } = useAxiosBeta<User>(endpoints.user.byId(wallet.user.id))
    // const [ execTopup, execTopupState ] = useLazyAxios(endpoints.wallets.topupById(wallet.id))
    
    const setCustomAmount = () => {
        setSelectedSuggestedAmount(undefined);
    }
    
    const setSuggestedAmount = (am: number) => {
        setSelectedSuggestedAmount(am);
        setAmount(am);
    }
        
    const incrementAmount = (add: number) => {
        setCustomAmount();
        setAmount(s => s + add)
    };
    const decrementAmount = (decr: number) => {
        setCustomAmount();
        setAmount(s => s - decr)
    };
    
    const confirmTopup = async (am: number) => {
        setTopupState('loading');
        try {
            await axios(endpoints.wallets.topupById(wallet.id), {
                method: 'POST',
                data: {
                    amount: am,
                },
                headers: {
                    Authorization: `Bearer ${authUser?.token}`,
                },
            });
            onSuccess?.();
            setTopupState('success');
        } catch (error) {
            setTopupState('error');
        }
    }
    
    const isActive = active && !!userData && !!wallet;
    const showLoader = !userData || topupState === 'loading';
    const showConfirmation = topupState === 'success';
    
    const reset = () => {
        setAmount(0);
        setTopupState('ilde');
        setTopupState('ilde');
    }
    
    const handleClose = () => {
        reset();
        onClose?.();
    }
        
    return (
        <Popover active={ isActive } onClose={ handleClose } { ...otherProps }>
            { showLoader ? <Loader /> : (<>
                { showConfirmation && <div>
                    <div className="mb-8">
                        <h3 className="popover__title">Topup geslaagd!</h3>
                        <h4 className="popover__subtitle">Uw krediet is toegevoegd aan uw portemonnee.</h4>
                    </div>
                    <Button onClick={ reset }>Nog een topup</Button>
                    <Button onClick={ handleClose } theme="secondary" className="mt-4">Sluiten</Button>
                </div>}
                { !showConfirmation && <Content 
                    user={ userData }
                    currentAmount={ amount }
                    decrementAmount={ decrementAmount }
                    incrementAmount={ incrementAmount }
                    selectedSuggestedAmount={ selectedSuggestedAmount }
                    setSuggestedAmount={ setSuggestedAmount }
                    confirmTopup={() => confirmTopup(amount)}
                />}
            </>) }
        </Popover>
    )
}

export default TopupPopover;