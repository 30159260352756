export const openSessionByBeacon = () => {
    console.log('open')
    navigator.sendBeacon(process.env.REACT_APP_API_URL + '/user/session/status', 'open');
}

export const closeSessionByBeacon = () => {
    navigator.sendBeacon(process.env.REACT_APP_API_URL + '/user/session/status', 'end');
}

export const closeSessionByBeaconIfUnfocused = () => {
    if (document.visibilityState === 'hidden') {
        closeSessionByBeacon();
    }
}
