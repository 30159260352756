import { FC, ReactElement } from 'react';
import { Button, Icon } from '../../basics';
import clientPackageInfo from '../../../../package.json';
import { Link } from 'react-router-dom';

type Props = {
    children?: ReactElement | ReactElement[];
};

const AboutPage: FC<Props> = ({ children }) => {   
    return (
        <div className="pt-8 px-8">
            <div className="mb-6">
                <div className="pre-heading">Applicatie</div>
                <div className="heading">Info</div>
            </div>
                        
            <div className="mb-4">
                <h4 className="text-xl text--main mb-4">Algemeen</h4>
            </div>
            
            <div className="mb-4">
                <h5 className="text--main mb-2">Versie</h5>
                <p className="text--secondary text-sm">{ clientPackageInfo.version }</p>
            </div>
            
            <div className="mb-4">
                <h5 className="text--main mb-2">Software van derden</h5>
                <p className="text--secondary text-sm"><Link to="packages">
                    <Button icon="arrow-right" theme="simple" small fitWidth>Bekijken</Button>
                </Link></p>
            </div>
            
            <hr className="my-6" />
            
            <div className="mb-4">
                <h4 className="text-xl text--main mb-4">Resources</h4>
            </div>
            
            <div className="mb-4">
                <h5 className="text--main mb-2">Icons</h5>
                <p className="text--secondary text-sm flex">
                    <Icon name="remixicon" className="mr-1" />
                    <span className="translate-y-0.5">Remix Icons</span>
                </p>
            </div>
            
            <div className="mb-4">
                <h5 className="text--main mb-2">Afbeeldingen</h5>
                <p className="text--secondary text-sm flex">
                    <Icon name="unsplash" className="mr-1" />
                    <span className="translate-y-0.5">Unsplash</span>
                </p>
            </div>
            
            <hr className="my-6" />
            
            <div className="mb-4">
                <h4 className="text-xl text--main mb-4">Deployment</h4>
                <p className="text--main text-sm">De front-end infrastructuur van de applicatie wordt gebuild en gehost op Vercel.</p>
            </div>
            
            <div className="mb-4">
                <h5 className="text--main mb-2">Vercel omgeving</h5>
                <p className="text--secondary text-sm">{ process.env['REACT_APP_VERCEL_ENV'] || 'Niet beschikbaar' }</p>
            </div>
            
            <div className="">
                <h5 className="text--main mb-2">Vercel deployment</h5>
                <p className="text--secondary text-sm">{ process.env['REACT_APP_VERCEL_URL'] || 'Niet beschikbaar' }</p>
            </div>
            
            <hr className="my-6" />
            
            <div className="mb-4">
                <h4 className="text-xl text--main mb-4">Versiebeheer</h4>
                <p className="text--main text-sm">Code is beschikbaar en wordt beheerd via GitHub.</p>
            </div>
            
            <div className="mb-4">
                <h5 className="text--main">Laatste commit</h5>
                { process.env.NODE_ENV === 'development' ? (
                    <div className="text--secondary text-sm">Info is niet beschikbaar tijdens Development</div>
                ) : (<>
                    <p className="flex items-baseline w-full">
                        <Icon name="git-commit" className="translate-y-1 text--secondary mr-2" />
                        <span className="text--secondary text-sm overflow-hidden text-ellipsis whitespace-nowrap">
                            { process.env['REACT_APP_VERCEL_GIT_COMMIT_SHA'] || 'Niet beschikbaar'}
                        </span>
                    </p>
                
                    <a 
                        className="block mt-4"
                        href={ 'https://github.com/lennertderyck/bar.haegepooorters.be/commit/' + process.env['REACT_APP_VERCEL_GIT_COMMIT_SHA'] }
                    >
                        <Button theme="simple" icon="arrow-right" fitWidth small className="">Versie bekijken</Button>
                    </a>
                </>)}
            </div>
            
            {/* <hr className="my-6" /> */}
            
            {/* <div className="mb-4">
                <h4 className="text-xl text--main mb-4">Credits</h4>
            </div> */}
            
            <p className="text-sm text-center text--secondary label mt-8">Ontwikkeld door <a href="https://www.jung.gent" target="_blank" className="underline underline-offset-2 decoration-stone-300 dark:decoration-stone-700">JUNG.GENT</a> voor Haegepoorters Destelbergen</p>
        </div>
    )
}

export default AboutPage;