import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const SwipeActionWrapper = styled.div`
    overflow: scroll;
    white-space: nowrap;
    width: 100%;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    
    &::-webkit-scrollbar {
        display: none;
    }
`;

const SwipeActionBase = styled.div`
    display: inline-flex;
    width: 100vw;
    scroll-snap-align: center;
    background-color: red;
`;

const SwipeActionAction = styled.div`
    display: inline-flex;
`;

type Props = PropsWithChildren<{}>;

const SwipeAction: FC<Props> = ({ children }) => {
    return (
        <SwipeActionWrapper>
            <SwipeActionAction>Action left</SwipeActionAction>
            <SwipeActionBase>{ children }</SwipeActionBase>
            <SwipeActionAction>Action right</SwipeActionAction>
        </SwipeActionWrapper>
    )
}

export default SwipeAction;