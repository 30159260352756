import { FC, ReactElement } from 'react';
import useAuth from '../../../../../states/hooks/useAuth/useAuth';
import useCart from '../../../../../states/hooks/useCart/useCart';
import { PopoverController } from '../../../../../states/hooks/usePopover/usePopover.types';
import { Button } from '../../../../basics';
import Pricfy from '../../../../basics/pricify/Pricify';
import { Popover } from '../../../../elements';

type Props = {
    controller: PopoverController;
    onPurchase: () => void;
};

const PurchaseConfirmPopover: FC<Props> = ({ controller, onPurchase }) => {
    const { selectedWallet } = useAuth();
    const { total, purchase } = useCart();

    return (
        <Popover { ...controller }>
            <div className="mb-6">
                <h3 className="popover__title">Je aankoop bevestigen</h3>
                <h4 className="popover__subtitle">Bevestig dat je <Pricfy>{ total }</Pricfy> zal betalen</h4>
            </div>
            <div className="mb-6">
                <div className="flex justify-between text--main">
                    <span>Wallet</span>
                    <span className="font-semibold">{ selectedWallet?.provider.label }</span>
                </div>
                <p className="text-stone-400 text-sm">Geselecteerde wallet</p>
                        
                <div className="flex justify-between mt-4 text--main">
                    <span>Subtotaal</span>
                    <span className="font-semibold"><Pricfy>{ total }</Pricfy></span>
                </div>
                <p className="text-stone-400 text-sm">Totaal van alle items</p>
                                    
                <div className="flex justify-between mt-4 text--main">
                    <span>Boete</span>
                    <span className="font-semibold">+ 0%</span>
                </div>
                <p className="text-stone-400 text-sm">Achterstallige betaling</p>
            </div>
            <Button theme="main" icon="arrow-right" loading={ purchase.loading } onClick={ onPurchase }>
                Aankoop bevestigen
            </Button>
        </Popover>
    )
}

export default PurchaseConfirmPopover;