import axios from 'axios';
import classNames from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useEndPoints from '../../../states/hooks/useEndpoints/useEndpoints';
import useHaptic from '../../../states/hooks/useHaptic/useHaptic';
import useOnScreen from '../../../states/hooks/useOnScreen/useOnScreen';
import { ID } from '../../../types/general';
import { Transaction, Wallet } from '../../../types/wallet';
import { sortDisabledWallets } from '../../../utils/funcs/algorithms/sorting';
import { AnimatedList, Icon } from '../../basics';
import Scrollable from '../scrollableCards/Scrollable';
import ScrollableCards from '../scrollableCards/ScrollableCards';
import ThreeDee from '../ThreeDee/ThreeDee';
import TransactionList from '../transactions/TransactionList';
import UserWalletOnboardingPopover from '../UserWalletOnboardingPopover/UserWalletOnboardingPopover';
import WalletCard from '../walletCard/WalletCard';

type WalletPreviewProps = {
    wallet: Wallet;
    onVisible?: () => void;
}

const WalletPreviewCard: FC<WalletPreviewProps> = ({ wallet, onVisible }) => {
    const cardRef = useRef<any>();
    const intersection = useOnScreen(cardRef, {
        threshold: 1
    });
    
    useEffect(() => {
        if (intersection?.isIntersecting) {
            onVisible?.();
        }
    }, [intersection?.isIntersecting])
    
    return (
        <Scrollable 
            ref={ cardRef }
            perView={ 1 } 
            snapStop="always"
        >
            <Link 
                className={classNames('w-full', !wallet.provider.enabled && 'opacity-50')}
                to={ `/user/wallets/${ wallet.id }` } 
            >
                <ThreeDee enabled={ intersection?.isIntersecting }>
                    <WalletCard wallet={ wallet } key={ wallet.id } />
                </ThreeDee>
            </Link>
        </Scrollable>
    )
}

type Props = {
    showInitalizePopover?: boolean;
    byUser?: ID;
    onWalletSelect?: (wallet: Wallet) => void;
    transactionsLimit?: number;
};

const WalletPreviewScrollList: FC<Props> = ({ byUser, onWalletSelect, showInitalizePopover, transactionsLimit }) => {
    const [ vibrate ] = useHaptic([50]);
    const navigate = useNavigate();
    const endpoints = useEndPoints();
    const { user } = useAuth();
    
    const finalEndpoint = byUser ? endpoints.wallets.byUserId(byUser) : endpoints.user.wallets;
    const { data: wallets } = useAxiosBeta<Wallet[]>(finalEndpoint);
    const [ transactions, setTransactions ] = useState<Transaction[]>([]);
    
    const transactionsAmountQueryString = useMemo(() => (
        !!transactionsLimit ? '?limit=' + transactionsLimit : ''
    ), [transactionsLimit])
        
    const handleCardInView = async (wallet: Wallet) => {
        vibrate();
        onWalletSelect?.(wallet);
        const response = await axios(endpoints.transactions.byUserWalletId(wallet.id) + transactionsAmountQueryString, {
            headers: {
                'Authorization': 'Bearer ' + user?.token
            }
        })
        
        setTransactions(response.data);
    }
    
    const sortedWallets = useMemo(() => {
        return wallets?.sort(sortDisabledWallets);
    }, [wallets]);
    const filteredWallets = (sortedWallets || []).filter((wallet) => wallet.provider.enabled === true);

    
    if (wallets?.length === 0) return <>
        <p className="text--secondary px-8">{ !!byUser ? 'Deze gebruiker heeft nog geen wallets' : 'Je heb nog geen wallets' }</p>
        <UserWalletOnboardingPopover show={ !!showInitalizePopover } /> 
    </>
    
    return (
        <>
            { filteredWallets.length > 0 ? 
                <ScrollableCards className="py-12 -my-12">
                    { filteredWallets?.map((wallet) => (
                        <WalletPreviewCard 
                            key={ wallet.id }
                            wallet={ wallet } 
                            onVisible={() => handleCardInView(wallet)}
                        />
                    ))}
                </ScrollableCards> :
                <div className="p-4 bg-stone-100 text--secondary rounded-lg text-sm mx-8">
                    We vonden geen wallets
                </div>
            }
            <div className="px-8 mt-6">
                <div className="flex justify-between items-baseline">
                    <h3 className="text-lg text--main">Transacties</h3>
                    <Link to="user/transactions" className="label text-stone-500 flex items-center gap-1">
                        Bekijk transacties
                        <Icon name="arrow-right" size="1rem" />
                    </Link>
                </div>
                { transactions.length === 0 && <p className="text--secondary mt-2">Geen transacties gevonden voor wallet</p> }
                <TransactionList 
                    transactions={ transactions }
                    onClick={transaction => navigate('/user/transactions/' + transaction.id)}
                />
            </div>
        </>
    )
}

export default WalletPreviewScrollList;