import { useState } from "react"
import { useEffectOnce } from "../useEffectOnce/useEffectOnce"
import UseOnScreen from "./useOnScreen.types"

const useOnScreen: UseOnScreen = (ref, options) => {
    const [intersectionEntry, setIntersectionEntry] = useState<IntersectionObserverEntry>()
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersectionEntry(entry)
      },
      {
        threshold: [.1, .2, .3, .4, .5, .6, .7, .8, .9, 1],
        ...options
      }
    )
  
    useEffectOnce(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    })
  
    return intersectionEntry
}

export default useOnScreen;