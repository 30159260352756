import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { Product } from '../../../types/bar';
import { Pricify } from '../../basics';

interface Props extends React.BaseHTMLAttributes<HTMLDivElement> {
    product: Product;
};

const ProductCardBase: FC<Props> = ({ product, ...otherProps }) => {
    return (
        <div className={ classNames('flex-1', product.availability === 'unavailable' && 'opacity-50') } { ...otherProps }>
            <h3 className="text-lg leading-5 text--main">{ product.name } – <Pricify>{ product.price }</Pricify></h3>
            <p className="text-stone-500">{ product.category.name } – { product.quantity }</p>
        </div>
    )
}

export default ProductCardBase;