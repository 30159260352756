import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useEndPoints from '../../../states/hooks/useEndpoints/useEndpoints';
import { Wallet } from '../../../types/wallet';
import { Button, Icon, QR } from '../../basics';
import Pricfy from '../../basics/pricify/Pricify';
import { Popover, Shielded, ThreeDee, TransactionList, WalletCard } from '../../elements';

type Props = {
    children?: any;
};

const WalletDetailPage: FC<Props> = ({ children }) => {
    const [ showQrCode, setShowQrCode ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const endpoints = useEndPoints();
    const { data: selectedWallet } = useAxiosBeta<Wallet>(endpoints.user.walletById(id || ''));
            
    useEffect(() => {
        if (selectedWallet === null) {
            navigate('/user/wallets');
        }
    }, [selectedWallet])
    
    useEffect(() => {
        const f = new URL(window.location.href).searchParams.get('qr');
        if (f) {
            setShowQrCode(true);
        } else if (!!showQrCode) {
            setShowQrCode(false);
        }
    }, [location.search])
    
    if (!selectedWallet) return null
    return (
        <>
            <div className="px-8 pt-8 h-full flex flex-col">
                <div className="flex-1">
                    <Link to={'..'} className="label mb-4 text-stone-400 flex items-center">
                        <Icon name="arrow-left" className="mr-1" size="1rem" />
                        <span>Wallet overview</span>
                    </Link>
                    <div className="mb-6">
                        <div className="pre-heading">Wallet</div>
                        <div className="heading">{ selectedWallet.provider.label }</div>
                    </div>
                    <ThreeDee enabled>
                        <WalletCard wallet={ selectedWallet } />
                    </ThreeDee>
                    
                    <div className="flex gap-4 mt-6">
                        <div className="flex-1 bg-stone-100 p-2 py-3 rounded-lg">
                            <Icon name="hand-coin" className="mx-auto text-stone-500 mb-1" />
                            <h4 className="text-center label text-sm text--secondary">Onder nul</h4>
                        </div>
                        <div className="flex-1 bg-stone-100 px-2 py-3 rounded-lg">
                            <Icon name="close-circle" className="mx-auto text-stone-500 mb-1" />
                            <h4 className="text-center label text-sm text--secondary">Uitgeschakeld</h4>
                        </div>
                    </div>
                    
                    {/* <p className="label text-sm text--secondary">Wallet uitgeschakeld</p> */}
                    {/* <TransactionList /> */}
                </div>
            
                <Shielded>
                    <Link to={`/manage/credit-providers/${ selectedWallet.provider.id }/edit`}>
                        <Button theme="secondary">Wallet bewerken</Button>
                    </Link>
                </Shielded>
            </div>
            <Popover active={ showQrCode } onClose={() => setShowQrCode(false)}>
                <div className="mb-6">
                    <div className="popover__title text-center">{ selectedWallet.provider.label }</div>
                    <div className="popover__subtitle text-center"><Pricfy>{ selectedWallet.balance }</Pricfy></div>
                </div>
                <div className="mx-auto w-fit">
                    <QR value={ selectedWallet.id } />
                </div>
            </Popover>
        </>
    )
}

export default WalletDetailPage;