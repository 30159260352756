import React, { FC } from 'react';
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import App from './App/App';
// @ts-ignore
import FrontView from './FrontView/FrontView';
import BackOffice from './BackOffice/BackOffice';
import useFlags from '../states/hooks/useFlags/useFlags';
import classNames from 'classnames';
import AxiosContextProvider from '../states/hooks/useAxiosBeta/context/axiosContext';

type Props = {
};

const SitesRoot: FC<Props> = () => {
    const darkMode = useFlags(s => s.flagById('dark_mode'));
    
    return (
        <AxiosContextProvider>
            <div className={ classNames(
                'h-full',
                darkMode?.state === true && 'dark',
            )}>
                <div className="bg-white dark:bg-stone-900 h-full">
                    <BrowserRouter>
                        <Routes>
                            <Route index element={ <FrontView />} />
                            <Route path="backoffice/*" element={ <BackOffice />} />
                        </Routes>
                    </BrowserRouter>
                    <BrowserRouter basename="app">
                        <App />
                    </BrowserRouter>
                </div>
            </div>
        </AxiosContextProvider>
    );
}

export default SitesRoot;