import { useState } from "react";
import { Error } from "../../../types/general";
import UseClipboard from "./useClipboard.types";

const useClipboard: UseClipboard = () => {
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<Error | null>(null);
    
    const initiate = () => {
        setError(null);
        setLoading(true);
    }
    
    const done = () => {
        setLoading(false);
    }
    
    const copy = async (data: string) => {
        initiate();
        try {
            const promise = await navigator.clipboard.writeText(data);
            return promise;
        } catch (error: Error) {
            setError(error);
        }
        done();
    }
    
    return {
        copy,
        loading,
        error
    }
}

export default useClipboard;