import { ID } from "../../types/general";

const origin = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export default {
    server: {
        preflight: origin + '/status'
    },
    auth: {
        login: origin + '/authn/login',
        loginV2: origin + '/v2/authn/login',
        register: origin + '/authn/register',
    },
    roles: {
        all: origin + '/roles'
    },
    user: {
        all: origin + '/users',
        byId: (id: ID) => origin + `/users/${id}`,
        purchase: origin + '/user/purchase',
        wallets: origin + '/user/wallets',
        addWallet: origin + '/user/wallet',
        transactions: origin + '/user/transactions',
        availableCreditProviders: origin + '/user/credit-providers',
        availableCreditProviderById: (id: ID) => origin + '/user/credit-provider/' + id,
        walletById: (id: ID) => origin + '/user/wallet/' + id,
        sessions: origin + '/user/sessions',
    },
    creditProviders: {
        base: origin + '/credit-provider',
        public: origin + '/credit-providers/public',
        all: origin + '/credit-providers/all',
        byId: (id: ID) => origin + '/credit-provider/' + id,
    },
    products: {
        all: origin + '/products',
        byId: (id: ID) => origin + '/products/' + id,
    },
    transactions: {
        all: origin + '/transactions',
        byId: (id: ID) => origin + '/transactions/' + id,
        byUserWalletId: (walletId: ID) => origin + `/user/wallet/${walletId}/transactions`
    },
    wallets: {
        byId: (id: ID) => origin + '/wallets/' + id,
        byUserId: (userId: ID) => origin + `/users/${userId}/wallets`,
        topupById: (walletId: ID) => origin + `/user/wallet/${ walletId }/topup`,
    },
    unsplash: {
        searchByQuery: (query: string) => origin + `/service/unsplash/request/search/photos?query=${query}&per_page=15`,
        imageById: (id: string, type: string = 'small') => origin + `/service/unsplash/imageById/${id}?type=${type}`,
    },
    menu: origin + '/menu',
    app: {
        version: origin + '/version/check'
    },
    npm: {
        packageInfo: (packageName: string, version: string) => origin + `/service/npm/package?package=${ packageName }&version=${ version }`
    },
    purchase: {
        verify: origin + `/user/purchase/verify`
    },
}