import classNames from 'classnames';
import { FC, MouseEvent, ReactElement, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StatsOverviewPage from './StatsOverviewPage/StatsOverviewPage';
import StatsPurchasesPage from './StatsPurchasesPage/StatsPurchasesPage';
import StatsTopupsPage from './StatsTopupsPage/StatsTopupsPage';

type Props = {
    children?: ReactElement | ReactElement[];
};

const StatsIndexPage: FC<Props> = ({ children }) => {
    const [ currentFilter, setFilter ] = useState('overview');
    const statPages = [
        { label: 'Overzicht', filter: 'overview', render: () => <StatsOverviewPage onReferClick={() => setFilter('purchases')} /> },
        { label: 'Uitgaven', filter: 'purchases', render: () => <StatsPurchasesPage /> },
        { label: 'Top-ups', filter: 'topups', render: () => <StatsTopupsPage /> },
    ]
    
    const PageToRender = useMemo(() => 
        statPages.find((page) => page.filter === currentFilter)?.render || 
        (() => <></>)
    , [currentFilter])
    
    const handleFilterSelect = (e: MouseEvent, selectedFilter: string) => {
        setFilter(selectedFilter);
        (e.target as HTMLDivElement).scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
    
    return (
        <div className="pt-8 flex flex-col">
            <div className="mb-6">
                <div className="px-8">
                    <h3 className="pre-heading">Statistieken</h3>
                </div>
                <div className="">
                    <ul className="relative z-10 overflow-x-scroll overflow-y-visible whitespace-nowrap scrollbar-hide snap-x snap-mandatory scroll-px-8 px-8 scroll-smooth">
                        {
                            statPages.map((page) => {
                                const active = page.filter === currentFilter;
                                
                                return (
                                    <li className="heading inline-block snap-start mr-6 last:mr-0 scroll-smooth">
                                        <button className={classNames(
                                            'pb-4 border-b border-black', 
                                            active ? 'border-opacity-100 text--main' : 'border-opacity-0 text--secondary'
                                        )} onClick={(e) => handleFilterSelect(e, page.filter)}>{ page.label }</button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <hr className="border--themed -translate-y-[2px]" />
                </div>
            </div>
            <div className="flex-1">
                <PageToRender />
            </div>
        </div>
    )
}

export default StatsIndexPage;