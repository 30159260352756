import UseLocationSearch from "./useLocationSearch.types";

const useLocationSearch: UseLocationSearch = () => {
    const current = new URL(window.location.href);
    
    const search = Object.fromEntries(current.searchParams);
    const toString = current.search;
    
    return {
        search,
        toString,
        __location: current,
    }
}

export default useLocationSearch;