import { FC, ReactElement, useState } from 'react';
import { CartItem, Product } from '../../../../../types/bar';
import useCart from '../../../../../states/hooks/useCart/useCart';
import useEndPoints from '../../../../../states/hooks/useEndpoints/useEndpoints';
import useAxiosBeta from '../../../../../states/hooks/useAxiosBeta/useAxiosBeta';
import { AnimatedList } from '../../../../basics';
import { ProductCard } from '../../../../elements';
import useFlags from '../../../../../states/hooks/useFlags/useFlags';
import ProductDetailPopup from '../ProductDetailPopup/ProductDetailPopup';

type Props = {
};

const ProductList: FC<Props> = () => {
    const { flagById } = useFlags();
    const [ selectedProduct, setSelectedProduct ] = useState<CartItem>()
    const endpoints = useEndPoints();
    const { addItem, getItem, removeSingle, total, purchase, startPurchase, items } = useCart();
    const { data } = useAxiosBeta<Product[]>(endpoints.menu);
    
    const handleCartAdd = (item: Product) => addItem(item);
    
    const handleCartAddition = (product: CartItem, showProductDetail?: boolean) => {
        if (showProductDetail) setSelectedProduct(product);
        handleCartAdd(product)
    }

    const filteredProducts = data?.filter((product) => product.availability !== 'hidden' ) || [];
    const mergedCartProducts = filteredProducts
        .map((product) => {
            const itemInCart = getItem(product);
            
            return {
                ...product,
                ...itemInCart
            }
        })
        .sort((A, B) => {
            
        if (A.amount === 0) {
            return 1;
        } else if (B.amount === 0) {
            return -1;
        } else {
            return 0;
        }
    })
    
    const showCartAdditionPopover = !!flagById('prod_popup')?.state
    
    return (
        <>
            <AnimatedList>
                { mergedCartProducts?.map((product) => {                    
                    return (
                        <ProductCard
                            clearUnavailable
                            key={ product.id } 
                            product={ product }
                            onInfoDisplay={() => setSelectedProduct(product)}
                            onRemoveItem={() => removeSingle(product)}
                            onAddItem={() => handleCartAddition(product, showCartAdditionPopover)}
                        />
                    )
                })}
            </AnimatedList>
            { selectedProduct && <ProductDetailPopup
                selectedProduct={ selectedProduct }
                onClose={() => setSelectedProduct(undefined)}
            />}
        </>
    )
}

export default ProductList;