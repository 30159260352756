import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApp } from '../../../../states/hooks/useApp/useApp';
import useAuth from '../../../../states/hooks/useAuth/useAuth';
import useSessionStore from '../../../../states/stores/useSessionStore';
import { Session } from '../../../../types/verification';
import { Button, Icon } from '../../../basics';
import { Popover, UserListItem } from '../../../elements';
import AccountSwitchButton from './AccountSwitchButton';

type Props = {
    children?: any;
};

const UserIndexPage: FC<Props> = ({ children }) => {
    const { updateAvailable } = useApp();
    const navigate = useNavigate();
    const [ showUserSwitchPopover, setShowUserSwitchPopover] = useState(false);
    const storedSessions = useSessionStore(s => s.stored);
    const { user, logout } = useAuth();
    
    const menuItems = [
        {
            label: 'Wallets',
            subLabel: (user?.wallets || []).filter((wallet) => wallet.provider.enabled === true).length + ' beschikbaar',
            icon: 'wallet',
            route: '/user/wallets',
        },
        {
            label: 'Transacties',
            icon: 'history',
            route: '/user/transactions',
        },
        {
            label: 'Flags',
            icon: 'flag',
            route: '/user/flags',
        },
        {
            label: 'Apparaten',
            icon: 'device',
            route: '/user/sessions',
        },
        {
            label: 'Informatie',
            subLabel: 'Over deze applicatie',
            icon: 'information',
            route: '/info',
            append: updateAvailable && (
                <Link to="/info/update">
                    <Button icon="download" iconPlacement="start" theme="simple" small fitWidth>Update beschikbaar</Button>
                </Link>
            )
        },
    ]
    
    const handleUserSwitch = (session: Session) => {
        logout();
        navigate('/session/' + session.id, { replace: true })
    }
    
    return (
        <>
            <div className="pt-8 flex flex-col h-full">
                <div className="flex-1">
                    <div className="mb-6 px-8">
                        <div className="pre-heading">Hi { user?.user.firstName }</div>
                        <div className="heading">Account</div>
                    </div>
                    <div className="w-full align-middle border-b border-stone-300 dark:border-stone-700 flex items-baseline px-8 pb-4">
                        <Icon name="account-circle" size="1.6rem" className="mr-4 translate-y-1 text--main" />
                        <div className="w-full h-full -translate-y-1">
                            <Link to="/user/account" className="w-full">
                                <span className="block text-lg leading-5 text--main">Profiel</span>
                                <span className="block text-stone-400 text--secondary">{ user?.user.email }</span>
                            </Link>
                            <Button theme="simple" className="mt-4" fitWidth onClick={() => setShowUserSwitchPopover(true)} simple small icon="arrow-left-right" iconPlacement="start">Gebruiker wisselen</Button>
                        </div>
                    </div>
                    <div className="px-8 mt-2">
                        { menuItems.map(({ label, icon, route, subLabel, append }) => (
                            <div className="w-full align-middle border-b border-stone-300 dark:border-stone-700 flex items-baseline py-4">
                                <Icon name={ icon } size="1.6rem" className="mr-4 translate-y-1 text--main" />
                                <div className="w-full h-full -translate-y-1">
                                    <Link to={ route } className="w-full">
                                        <span className="block text-lg leading-5 text--main">{ label }</span>
                                        <span className="block text-stone-400 text--secondary">{ subLabel }</span>
                                    </Link>
                                    { append && <div className="mt-4">
                                        { append }
                                    </div>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                
                <p className="text-sm text-center text--secondary label mt-8">Design & Dev door <a href="https://www.jung.gent" target="_blank" className="underline underline-offset-2 decoration-stone-300 dark:decoration-stone-700">JUNG.GENT</a></p>
            </div>
            
            <Popover active={ showUserSwitchPopover } onClose={() => setShowUserSwitchPopover(false)}>
                <div className="mb-6">
                    <h3 className="popover__title">Gebruiker wisselen</h3>
                    <h3 className="popover__subtitle">Account kiezen</h3>
                </div>
                <ul>
                    { storedSessions.map((session, index) => {
                        const isCurrentAuthenticatedUser = user?.user.id === session.user.id;
                        
                        return (
                            <li key={ index } className="group">
                                <UserListItem user={ session.user } isActive={ isCurrentAuthenticatedUser } onClick={() => !isCurrentAuthenticatedUser && handleUserSwitch(session)} />
                            </li>
                        )
                    })}
                </ul>
                <div className="mt-6">
                    <Button theme="secondary" onClick={() => logout('/session/new')}>Gebruiker toevoegen</Button>
                </div>
            </Popover>
        </>
    )
}

export default UserIndexPage;