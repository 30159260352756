import { useState } from "react";
import UsePopover from "./usePopover.types";

const usePopover: UsePopover = (defaultState: boolean = false) => {
    const [ active, setActive ] = useState<boolean>(defaultState);
    
    const open = () => setActive(true);
    const close = () => setActive(false);
    const toggle = (state?: boolean) => setActive(s => state || !s);
    
    const controller = {
        active,
        onClose: close
    }
        
    return [ 
        controller, 
        {
            toggle,
            open,
            close,
            state: active
        }
    ]
}

export default usePopover;