import { FC, useEffect, useState } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { ID } from '../../../types/general';
import { Transaction } from '../../../types/wallet';
import { AnimatedList } from '../../basics';
import TransactionSummary from '../../pages/userPages/UserTransactionsPage/TransactionSummary';
import Popover from '../popover/Popover';
import TransactionListItem from './TransactionListItem';

type Props = {
    transactions?: Transaction[];
    requestedTransaction?: ID;
    hidePopup?: boolean;
    onClick?: (transaction: Transaction) => void;
    onRefresh?: () => Promise<any>
};

const TransactionList: FC<Props> = ({ transactions, onClick, requestedTransaction, hidePopup = false }) => {
    const [ transactionPopupDetail, setTransactionPopupDetail ] = useState<Transaction>()
    
    useEffect(() => {
        if (!!transactions && !!requestedTransaction) {
            const result = transactions.find((transaction) => transaction.id === requestedTransaction);
            setTransactionPopupDetail(result);
        }
    }, [ transactions, requestedTransaction ])
    
    const deselectTransaction = () => {
        setTransactionPopupDetail(undefined)
    }
    
    const handleClick = (transaction: Transaction) => {
        if (!hidePopup) {
            setTransactionPopupDetail(transaction);
        }
        
        if (onClick) {
            onClick(transaction)
        }
    }
    
    
    return (
        <>
            {/* <PullToRefresh onRefresh={onRefresh || (() => new Promise<any>(() => {}))}> */}
                <AnimatedList>
                    { transactions?.map(transaction => (
                        <li 
                            className="border-b border-stone-300 dark:border-stone-700 last:border-b-0"
                            onClick={() => handleClick(transaction)}
                        >
                            <TransactionListItem transaction={ transaction } />
                        </li>
                    ))}
                </AnimatedList>
            {/* </PullToRefresh> */}
            <Popover active={ !!transactionPopupDetail } onClose={ deselectTransaction }>
                { !!transactionPopupDetail && <TransactionSummary transaction={transactionPopupDetail} />}
            </Popover>
        </>
    )
}

export default TransactionList;