import classNames from 'classnames';
import { FC, memo, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useEndPoints from '../../../states/hooks/useEndpoints/useEndpoints';
import useFlags from '../../../states/hooks/useFlags/useFlags';
import { Wallet } from '../../../types/wallet';
import { Icon, Pricify } from '../../basics';
import Popover from '../popover/Popover';
// import styles from './WalletCard.module.scss';

type Props = {
    wallet: Wallet;
    onlyBalance?: boolean;
    preview?: boolean;
};

const WalletCard: FC<Props> = memo(
    ({ wallet, onlyBalance, preview }) => {   
        const navigate = useNavigate();
        const endpoints = useEndPoints();
        const { flagById } = useFlags();

        const showQRScanner = flagById('qr_scanner_enabled')?.state;
        
        const showQrCode = (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            
            navigate('/user/wallets/' + wallet.id + '?qr=true');
        }
        
        return (
            <>
                <div 
                    className={classNames(
                        !preview && 'ratio-card',
                        'relative overflow-hidden',
                        'rounded-xl select-none',
                    )}
                >
                    { wallet.provider.coverImage ? (<>
                        <img
                            className="absolute inset-0 w-full h-full object-cover blur-sm"
                            src={ endpoints.unsplash.imageById(wallet.provider.coverImage, 'small') } 
                        />
                        <div className={classNames(
                            'absolute inset-0 w-full h-full',
                            'bg-gradient-to-t',
                            'from-[#000000b8] to-transparent'
                        )} />
                    </>) : (
                        <div className={classNames(
                            'absolute inset-0 w-full h-full',
                            'bg-gradient-to-r',
                            'from-stone-200 via-stone-300 to-stone-400 dark:bg-black',
                            'dark:from-stone-800 dark:via-stone-700 dark:to-stone-600',
                        )} />
                    )}
                    <div className={classNames(
                        'relative z-10 h-full',
                        preview ? 'p-5' : 'flex justify-between p-6',
                    )}>
                        <div className="flex flex-col justify-between">
                            <div>
                                { !onlyBalance && <h4>
                                    { wallet.provider.label ? 
                                        <span className={classNames(
                                            wallet.provider.coverImage ? 'text-stone-200' : 'text--main',
                                            !preview && 'text-xl',
                                        )}>{ wallet.provider.label }</span> :
                                        <span className={classNames(wallet.provider.coverImage ? 'text-stone-200 opacity-50' : 'text--main')}>Geen naam</span>
                                    }
                                </h4>}
                                <h3 className={ classNames('text-4xl', wallet.provider.coverImage ? 'text-stone-200' : 'text--main')}><Pricify>{ wallet.balance || 0 }</Pricify></h3>
                            </div>
                    
                            <div>
                                {( !preview && wallet.provider.verified ) && (
                                    <div className="flex items-center">
                                        <Icon name="check-double" className={classNames('mr-1', wallet.provider.coverImage ? 'text-stone-200 text-opacity-50' : 'text--secondary')} />
                                        <span className={classNames('label text-sm', wallet.provider.coverImage ? 'text-stone-200 text-opacity-50' : 'text--secondary')}>Geverifieerd</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {( showQRScanner && !preview ) && (
                            <button onClick={ showQrCode } className="h-fit">
                                <Icon name="qr-code" className="text--secondary" size="1.6rem" />
                            </button>
                        )}
                    </div>
                </div>
            </>
        )
    }
)

export default WalletCard;