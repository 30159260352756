import {
    Route, 
} from "react-router-dom";
// @ts-ignore
import SlideRoutes from 'react-slide-routes';
import { FC } from 'react';
import { AboutPackagesPage, AboutPage, AddSessionPage, CartPage, FeatureFlagsPage, LoginPage, ManagePage, NotFoundPage, ProductCreatePage, ProductEditPage, ProductManageIndexPage, ProductsListPage, SessionIndexPage, StartPage, StatsIndexPage, UpdatePage, UserIndexPage, UserProfilePage, UserRegisterPage, UserTransactionsPage, WalletDetailPage, WalletEditPage, WalletJoinPage, WalletOverviewPage } from "../../pages";
import ProtectedRoute from "../protectedRoute/ProtectedRoute";
import RouterOutlet from "../routerOutlet/RouterOutlet";
import Footer from "../footer/Footer";
import styled from "styled-components";
import useAuth from "../../../states/hooks/useAuth/useAuth";
import NotificationPermissionPopover from "../notificationPermissionPopover/NotificationPermissionPopover";
import QrScanPopover from "../qrScanPopover/QrScanPopover";
import UserManagePage from "../../pages/managePage/UserManagePage/UserManagePage";
import AppInstallWrapper from "../appInstallWrapper/AppInstallWrapper";
import UserOverviewManagePage from "../../pages/managePage/UserManagePage/UserOverviewManagePage";
import Shielded from "../shielded/Shielded";
import ThreeDee from "../ThreeDee/ThreeDee";
import CreditProviderManagePage from "../../pages/managePage/CreditProviderManagePages/CreditProviderManagePage";
import UserSessionsPage from "../../pages/userPages/UserSessionsPage/UserSessionsPage";
import CreateCreditProviderPage from "../../pages/managePage/CreditProviderManagePages/CreateCreditProviderPage";

type Props = {
    children?: any;
};

// const FooterWrapper = styled.div`
//     box-shadow: 0px 1px 0px rgba(17,17,26,0.05), 0px 0px 8px rgba(17,17,26,0.1);
// `;

const Router: FC<Props> = ({ children }) => {
    const { user } = useAuth();
    
    return (
        <>
            <ThreeDee />
            <div className="h-screen flex flex-col">
                <div className="flex-1 overflow-scroll">
                    {/* <PullToRefresh
                        resistance={3}
                        onRefresh={() => new Promise<void>((resolve) => {
                            setTimeout(() => {
                                window.location.reload()
                                resolve();
                            }, 1000)
                        })}
                    > */}
                        <SlideRoutes>
                            <Route element={<RouterOutlet />}>
                                <Route element={ <AppInstallWrapper><RouterOutlet /></AppInstallWrapper>}>
                                    <Route path="session">
                                        <Route index element={ <SessionIndexPage /> } />
                                        <Route path=":id" element={ <LoginPage /> } />
                                        <Route path="new" element={ <AddSessionPage /> } />
                                        <Route path="register" element={ <UserRegisterPage /> } />
                                    </Route>
                                    <Route path="bar" element={ <ProtectedRoute><RouterOutlet /></ProtectedRoute> }>
                                        <Route index element={ <ProductsListPage /> } />
                                        <Route path=":id" element={ <ProductsListPage /> } />
                                        <Route path="drivethru/:productId" element={ <ProductsListPage drivethru /> } />
                                    </Route>
                                    <Route path="statistics" element={ <ProtectedRoute><RouterOutlet /></ProtectedRoute> }>
                                        <Route index element={ <StatsIndexPage /> } />
                                        {/* <Route path=":filter" element={ <StatsIndexPage /> } /> */}
                                    </Route>
                                    {/* <Route path="cart" element={ <ProtectedRoute><RouterOutlet /></ProtectedRoute> }>
                                        <Route index element={ <CartPage /> } />
                                    </Route> */}
                                    <Route path="shared/:type/:data" element={ <></> } />
                                    <Route path="manage" element={ <ProtectedRoute><Shielded type="page"><RouterOutlet /></Shielded></ProtectedRoute> }>
                                        <Route index element={ <ManagePage /> } />
                                        <Route path="users" element={ <RouterOutlet /> }>
                                            <Route index element={ <UserOverviewManagePage /> } />
                                            <Route path=":id" element={ <UserManagePage /> } />
                                        </Route>
                                        <Route path="products" element={ <RouterOutlet /> }>
                                            <Route index element={ <ProductManageIndexPage /> } />
                                            <Route path=":id" element={ <ProductEditPage /> } />
                                            <Route path="new" element={ <ProductCreatePage /> } />
                                        </Route>
                                        <Route path="credit-providers">
                                            <Route index element={ <CreditProviderManagePage /> } />
                                            <Route path=":id" element={ <WalletEditPage /> } />
                                            <Route path="new" element={ <CreateCreditProviderPage /> } />
                                        </Route>
                                    </Route>
                                    <Route path="info">
                                        <Route index element={ <AboutPage /> } />
                                        <Route path="packages" element={ <AboutPackagesPage /> } />
                                        <Route path="update" element={ <UpdatePage /> } />
                                    </Route>
                                    <Route path="/" element={ <ProtectedRoute><StartPage /></ProtectedRoute> } />
                                    <Route path="*" element={ <NotFoundPage />} />
                                </Route>
                                <Route path="user" element={ <ProtectedRoute><RouterOutlet /></ProtectedRoute> }>
                                    <Route element={ <AppInstallWrapper><RouterOutlet /></AppInstallWrapper> }>
                                        <Route index element={ <UserIndexPage /> } />
                                        <Route path="wallets" element={ <ProtectedRoute><RouterOutlet /></ProtectedRoute> }>
                                            <Route index element={ <WalletOverviewPage /> } />
                                            <Route path=":id" element={ <WalletDetailPage /> } />
                                            <Route path="join" element={ <WalletJoinPage /> } />
                                            <Route path="join/:id" element={ <WalletJoinPage /> } />
                                        </Route>
                                        <Route path="transactions" element={ <ProtectedRoute><RouterOutlet /></ProtectedRoute> }>
                                            <Route path=":id" element={ <UserTransactionsPage /> } />
                                            <Route index element={ <UserTransactionsPage /> } />
                                        </Route>
                                        <Route path="account" element={ <UserProfilePage /> } />
                                        <Route path="sessions" element={ <UserSessionsPage /> } />
                                    </Route>
                                </Route>
                                <Route path="user/flags">
                                    <Route index element={ <FeatureFlagsPage /> } />
                                    <Route path=":id" element={ <FeatureFlagsPage /> } />
                                </Route>
                            </Route>
                        </SlideRoutes>
                        
                    {/* </PullToRefresh> */}
                </div>
                { user && (
                    <>
                        <div className="flex justify-center pt-2">
                            <Footer />
                        </div>
                        <NotificationPermissionPopover />
                    </>
                )}
                <QrScanPopover />
            </div>
        </>
    )
}

export default Router;