import { AxiosRequestConfig } from 'axios';
import { FC, memo, ReactElement } from 'react';
import useAxiosBeta from '../../../states/hooks/useAxiosBeta/useAxiosBeta';
import { RequestInitializer } from '../../../states/hooks/useAxiosBeta/useAxiosBeta.types';
import { AnimatedList, Button, Icon } from '../../basics';
import Loader from '../loader/Loader';
// import path from 'object-path';

interface RenderProps {
    data: any;
    refetch: RequestInitializer
}

interface Props {
    url: string;
    // listPath?: string;
    config?: AxiosRequestConfig;
    allowRetry?: boolean;
    type?: 'list' | 'table';
    className?: string;
    disableAnimated?: boolean;
    children: (data: RenderProps) => ReactElement[] | ReactElement
};

const AxiosDataList: FC<Props> = ({ url, config = {}, type = 'list', children, disableAnimated, ...otherProps }) => {
    const { data, error, loading, refetch } = useAxiosBeta<any>(url, config);
    
    const DataRenderer = memo(() => (<>
        { children({
            data,
            refetch
        })}
    </>))
    
    if (loading && !error) return (<div className="py-4">
        <Loader />
    </div>)
    
    else if (error) return (<>
        <div className="text-center py-4">
            {/* <Icon name="cloud" className="mx-auto" size="1.6rem" /> */}
            <h5 className="text-lg text--main">Oeps, foutje</h5>
            <p className="text-sm text--secondary">We konden dit niet laden</p>
            
            <Button 
                small 
                theme="simple" 
                fitWidth 
                icon="restart" 
                iconPlacement="start" 
                className="mt-4 mx-auto"
                onClick={() => refetch()}
            >Probeer opnieuw</Button>
        </div>
    </>)
    
    else return (
        <AnimatedList type={ type } disable={ disableAnimated } { ...otherProps }>
            <DataRenderer />
        </AnimatedList>
    )
}

export default AxiosDataList;