import { FC, InputHTMLAttributes, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

const ToggleBase = styled.input`
    --thumb-size: 1rem;
    --spacing: 4px;
    --border-width: 2px;
    --border-color: #a8a29e;
    
    appearance: none;
    position: relative;
    width: 3.5rem;
    height: calc(
        var(--thumb-size) + 
        (var(--border-width) * 2) +
        (var(--spacing) * 2)
    );
    border: 2px solid var(--border-color);
    border-radius: 9999px;
    
    &:checked {
        --border-color: black;
    }
    
    &::after {
        content: ' ';
        
        position: absolute;
        left: var(--spacing);
        top: var(--spacing);
        width: var(--thumb-size);
        height: var(--thumb-size);
        background-color: black;
        border-radius: 100%;
        transform: translateX(0%);
    }
    
    &:checked::after {
        transform: translateX(calc(200% - var(--spacing)));
    }
`;

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
    
}

const ToggleInput: FC<Props> = ({ name, ...otherProps }) => {
    const { register } = useFormContext();
    
    const conditionalRegister = useMemo(() => ({
        ...(name ? register(name) : {})
    }), [name])
    
    return (
        <ToggleBase 
            type="checkbox" 
            { ...conditionalRegister }
            { ...otherProps } 
        />
    )
}

export default ToggleInput;