import { FC } from 'react';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { Transaction } from '../../../../types/wallet';
import { AxiosDataList } from '../../../elements';
import TransactionListItemFromUser from '../../../elements/transactions/TransactionListItemFromUser';

type Props = {
};

const StatsPurchasesPage: FC<Props> = () => {
    const endpoints = useEndPoints();
    
    return (
        <div className="px-8">
            <div className="-mt-4">
                <AxiosDataList url={ endpoints.transactions.all + '?limit=30' }>
                    {({ data }) => data?.map((transaction: Transaction) => (
                        <TransactionListItemFromUser transaction={ transaction } />
                    ))}
                </AxiosDataList>
            </div>
        </div>
    )
}

export default StatsPurchasesPage;