import { FC, useMemo } from 'react';

interface Props {
    reason?: string | null;
};

const CheckoutOverdraftNotify: FC<Props> = ({ reason }) => {
    const message = useMemo(() => {
        switch (reason) {
            case 'provider':
                return 'Deze wallet is uitgeschakeld'
            case 'overdraft':
                return 'Je hebt te weinig tegoed'
            default:
                return 'Fout bij checkout';
        }
    }, [ reason ])
    
    return (
        <div className="text-red-600 text-center text-sm w-fit bg-red-100 py-1.5 px-3 mx-auto rounded-b-2xl">
            { message }
        </div>
    )
}

export default CheckoutOverdraftNotify;