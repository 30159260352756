import { FC, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useAuth from '../../../../states/hooks/useAuth/useAuth';
import { Button, Icon } from '../../../basics';
import { Session, User } from '../../../../types/verification';
import { PinVerification, Popover, UserListItem } from '../../../elements';

type Props = {
};

const SessionIndexPge: FC<Props> = () => {
    const navigate = useNavigate();
    const { sessions } = useAuth();
    const [ selectedSession, selectSession ] = useState<Session>();
    
    const handleSessionSelect = (session: Session) => {
        selectSession(session);
    }
    
    const handleVerificationComplete = (user: User) => {
        selectSession(undefined);
        navigate('/');
    }
    
    const handleVerificationError = (error: any) => {}
    
    return (
        <>
            <div className="p-8 h-full flex flex-col">
                <div className="flex-1">
                    <div className="mb-8">
                        <div className="pre-heading">Accounts</div>
                        <div className="heading">Selecteer je account</div>
                    </div>
                    { sessions.sort().map(session => {
                        const lastSession = dayjs(session.lastAccess).fromNow()
                
                        return (
                            <div
                                key={ session.id }
                                title={ `Laatst aangemeld ${lastSession}` }
                            >
                                <UserListItem user={ session.user } onClick={() => handleSessionSelect(session)} />
                            </div>
                        )
                    })}
                </div>
                <Link to="/session/new">
                    <Button theme="secondary">Account toevoegen</Button>
                    <Link to="register">
                        <Button theme="simple" className="mt-6">Nog geen account?</Button>
                    </Link>
                </Link>
            </div>
            <Popover active={ !!selectedSession } onClose={() => selectSession(undefined)}>
                <PinVerification session={{ email: selectedSession?.user.email || '' }} onComplete={ handleVerificationComplete } onError={ handleVerificationError } />
            </Popover>
        </>
    )
}

export default SessionIndexPge;