import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AnimatedList, Button } from '../../../../components/basics';
import { WalletCard } from '../../../../components/elements';
import useAxiosBeta from '../../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { User } from '../../../../types/verification';

interface Props {};

const UserManagePage: FC<Props> = () => {
    const params = useParams<any>();
    const endpoints = useEndPoints();
    const { data: user } = useAxiosBeta<User>(endpoints.user.byId(params.id as string));
    
    if (!user) return null;
    return (
        <div className="container container--responsive py-12 mx-auto">
            <Link to="..">
            <Button icon="arrow-left" iconPlacement="start" fitWidth small theme="secondary" className="mb-8">Alle gebruikers</Button>
            </Link>
            <div className="mb-8">
                <h3 className="text-2xl font-semibold text--main">{ user.firstName } { user.lastName }</h3>
                <p className="text--secondary">{ user.email }</p>
            </div>
            
            <div className="mb-8">
                <h3 className="text-lg text--main mb-4">Wallets</h3>
                { user.wallets.length === 0 && <p className="text--secondary">Geen wallets gevonden.</p>}
                <AnimatedList className="grid grid-cols-3 gap-6">
                    { user.wallets.map((wallet) => (
                        <li key={ wallet.id } className="col-span-1">
                            <WalletCard wallet={ wallet } />
                        </li>
                    ))}
                </AnimatedList>
            </div>
            
            {/* <div className="mb-8">
                <h3 className="text-lg text--main mb-4">Transacties</h3>
            </div> */}
        </div>
    )
}

export default UserManagePage;