import { FC } from 'react';
import { Link } from 'react-router-dom';
import useAxiosBeta from '../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useEndPoints from '../../../states/hooks/useEndpoints/useEndpoints';
import { Wallet, CreditProvider } from '../../../types/wallet';
import { AnimatedList, Icon } from '../../basics';
import CreateWalletCard from '../createWalletCard/CreateWalletCard';
import WalletCard from '../walletCard/WalletCard';

type Props = {
};

const WalletsToJoinListing: FC<Props> = () => {
    const endpoints = useEndPoints();
    const { data: wallets, refetch: refreshWallets } = useAxiosBeta<Wallet[]>(endpoints.user.wallets);
    const { data: availableCreditProviders, refetch: refreshCreditAvailableProviders } = useAxiosBeta<CreditProvider[]>(endpoints.user.availableCreditProviders)
    
    const refreshData = () => {
        refreshWallets();
        refreshCreditAvailableProviders();
    }
    
    
    return (
        <>
            {
                availableCreditProviders?.length === 0 && <p className="text--secondary">Geen voorgestelde wallets beschikbaar</p>
            }
            <AnimatedList>
                {
                    availableCreditProviders?.map(creditProvider => (
                        <li key={ creditProvider.id } className="mb-4 last:mb-0">
                            <CreateWalletCard provider={ creditProvider } onCreated={ refreshData } />
                        </li>
                    ))
                }
            </AnimatedList>
        </>
    )
}

export default WalletsToJoinListing;