import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import { Button, Icon } from '../../basics';

type Props = {
    children?: any;
    required?: string[];
    type?: 'block' | 'page';
};

const Shielded: FC<Props> = ({ children, type = 'block' }) => {
    const { user } = useAuth();
    
    const hasPermission = user?.user.role.id === '62f2d12c528c0cb30e837b74';
    
    if (hasPermission) {
        return children;
    }
    
    else if (type === 'block') return null;
    else return (
        <div className="relative px-8 h-full flex flex-col items-center justify-center">
            <Icon name="shield-star" size="2.5rem" className="mb-2 text--secondary" />
            <h2 className="pre-heading text-center">Geen toegang</h2>
            <h3 className="heading text-center">Onvoldoende rechten</h3>
            
            <Link to="/" className="block mt-6 mx-auto absolute bottom-0">
                <Button icon="arrow-right" fitWidth small theme="secondary">Startpagina</Button>
            </Link>
        </div>
    )
}

export default Shielded;