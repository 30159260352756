import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../../../components/basics';
import { AxiosDataList } from '../../../../components/elements';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { User } from '../../../../types/verification';

type Props = {
};

const UsersOverviewPage: FC<Props> = () => {
    const navigate = useNavigate();
    const { user } = useEndPoints();
    
    return (
        <div className="container mx-auto py-12">
            <div className="mb-8">
                <h3 className="text-2xl font-semibold text--main">Accounts</h3>
            </div>
            <AxiosDataList url={ user.all } type="table" className="w-full">
                {({ data }) => <>
                    <tr>
                        <th className="text-left">Naam</th>
                        <th className="text-left">Email</th>
                        <th className="text-left">Rollen</th>
                    </tr>
                    { data?.map((user: User) => (    
                        <tr 
                            className="border-b border-stone-300 cursor-pointer hover:bg-stone-100"
                            onClick={() => navigate(user.id)}
                        >
                            {/* <Link to={ user.id } className="flex items-center gap-8"> */}
                                <td className="flex items-center py-4 gap-4">
                                    <Icon name="user" />
                                    <p>{ user.firstName } { user.lastName }</p>
                                </td>
                                <td className="py-4">{ user.email }</td>
                                <td className="py-4">{ user.role.name }</td>
                            {/* </Link> */}
                        </tr>
                    )) }
                </>}
            </AxiosDataList>
        </div>
    )
}

export default UsersOverviewPage;