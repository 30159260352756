import { FC, ReactElement } from 'react';
import { useUA } from 'use-ua-parser-js';
import { useApp } from '../../../states/hooks/useApp/useApp';
import useFlags from '../../../states/hooks/useFlags/useFlags';
import { Button } from '../../basics';

type Props = {
    children?: any;
};

const Instructions = () => {
    return (<>
        <ol className="list-decimal	list-inside text-sm">
            <li className="mb-2">Open deze site in safari</li>
            <li className="mb-2">Klik op de deel-knop (vierkantje met pijl omhoog)</li>
            <li className="mb-2">Klik op "Toevoegen aan startscherm"</li>
            <li>Ga naar je startscherm en open de app</li>
        </ol>
    </>)
}

const AppInstallWrapper: FC<Props> = ({ children }) => {
    const { flagById } = useFlags();
    const { installed, add2Screen } = useApp();
    
    const uaDetails = useUA();
    const isApple = uaDetails?.os.name === 'iOS';
    
    const inBrowserAllowed = flagById('force_in_browser');
    const forceNotify = false;
    
    if (inBrowserAllowed || (!forceNotify && (process.env.NODE_ENV === 'development' || !!installed))) return children;
    else return (
        <>
            { children }
            <div className="fixed top-0 bottom-0 left-0 right-0 flex flex-col justify-end bg-black bg-opacity-60 z-10">
                <div className="bg-white dark:bg-stone-800 p-8">
                    <div className="mb-6">
                        <h2 className="popover__title">Gebruik de app</h2>
                        <h3 className="popover__subtitle">Installeer of open de app</h3>
                    </div>
                    
                    <p className="text-sm text--secondary">De bar-app werkt beter als losstaande applicatie. Je kan deze installeren zonder extra geheugen te gebruiken.</p>
                    
                    <div className="mt-6"> 
                        { isApple ? <Instructions /> : <Button onClick={ add2Screen } >Installeren</Button>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppInstallWrapper;