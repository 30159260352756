import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../basics';
import { WalletPreviewScrollList } from '../../elements';
import WalletsToJoinListing from '../../elements/WalletsToJoinListing/WalletsToJoinListing';

type Props = {
};

const WalletOverviewPage: FC<Props> = () => {    
    return (
        <div className="pt-8 flex flex-col h-full">
            <div className="flex-1">
                <div className="mb-6 px-8">
                    <div className="pre-heading">Wallets</div>
                    <div className="heading">Overzicht</div>
                </div>
            
                <WalletPreviewScrollList transactionsLimit={ 30 } />
            
                <div className="px-8 mt-6">
                    <h4 className="text-lg text--main mb-3">Wallet toevoegen</h4>
                    <WalletsToJoinListing />
                </div>
            </div>
            <div className="px-8">
                <Link to="join" className="block">
                    <Button theme="secondary" small>Heb je een code?</Button>
                </Link>
                <p className="text--secondary mt-4 text-sm">
                    Verborgen (private) wallets kan je toevoegen met een code.
                </p>
            </div>
        </div>
    )
}

export default WalletOverviewPage;