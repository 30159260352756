import { FC, useState } from 'react';
import { Form, Input } from '../../../elements';
import { Button, ToggleInput } from '../../../basics';
import CreditOverdraftFormSection from './components/CreditOverdraftFormSection';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import useLazyAxios from '../../../../states/hooks/useAxiosBeta/useLazyAxios';
import { CreditProvider } from '../../../../types/wallet';
import { useNavigate, useParams } from 'react-router-dom';
import PhotoSelectorPopover from '../../../elements/PhotoSelectorPopover/PhotoSelectorPopover';

interface Props {};

const CreateCreditProviderPage: FC<Props> = () => {
    const navigate = useNavigate();
    const { id: walletId } = useParams();
    const endpoints = useEndPoints();
    const [ showImageSelector, setShowImageSelector ] = useState(false);
    const [ coverImage, setCoverImage ] = useState<any>(null)

    const [ saveCreditProvider, savedCreditProvider ] = useLazyAxios<CreditProvider>(endpoints.creditProviders.base, {
        method: 'POST',
    });
    
    const handleCreation = async (data: any) => {
        await saveCreditProvider({
            ...data,
            coverImage,
        });
        navigate('..');
    };
    
    const handleImageSelector = (photo: string) => {
        setShowImageSelector(false);
        setCoverImage(photo);
    }
    
    return (
        <>
            <div className="pt-8 px-8 h-full flex flex-col">
                    <div className="mb-6">
                        <h3 className="pre-heading">Credit provider</h3>
                        <h2 className="heading">Provider aanmaken</h2>
                    </div>
                    <Form
                        className="flex-1 flex flex-col"
                        onSubmit={ handleCreation }
                    >
                        <div className="flex-1">
                            <Input name="label" label="Label" className="mb-5" />
                            {/* <Input name="public" label="Publiek maken?" type="checkbox" className="mb-4" /> */}
                            <label className="flex items-center gap-3 mb-3">
                                <ToggleInput name="enabled" />
                                <div className="block text--main text-lg">Inschakelen</div>
                            </label>
                            <label className="flex items-center gap-3 mb-3">
                                <ToggleInput name="public" />
                                <div className="block text--main text-lg">Maak publiek</div>
                            </label>
                            <CreditOverdraftFormSection />
                            <div>
                                <div className="block text--main text-lg mb-1">Cover afbeelding</div>
                                { !coverImage ? 
                                    <Button type="button" theme="secondary" small onClick={() => setShowImageSelector(true)} className="mt-4">Kies afbeelding</Button> : 
                                    <>
                                        <div className="overflow-hidden ratio-card rounded-xl">
                                            <img src={ endpoints.unsplash.imageById(coverImage) } className="bg-stone-300 w-full object-cover blur-sm" />
                                        </div>
                                        <Button type="button" theme="secondary" small onClick={() => setShowImageSelector(true)} className="mt-4">Wijzig afbeelding</Button>
                                    </>
                                }
                            </div>
                        </div>
                
                        <Button type="submit" loading={ savedCreditProvider.loading }>Opslaan</Button>
                    </Form>
                </div>
            <PhotoSelectorPopover 
                active={ showImageSelector }
                onClose={() => setShowImageSelector(false)}
                onSelect={ handleImageSelector }
            />
        </>
    )
}

export default CreateCreditProviderPage;