import { createElement, FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';

interface Props extends React.HTMLAttributes<HTMLUListElement>, PropsWithChildren<{}> {
    type?: 'list' | 'table';
    disable?: boolean;
};

const AnimatedList: FC<Props> = ({ type = 'list', disable = false, ...otherProps }) => {
    const [ animationParent ] = useAutoAnimate<HTMLElement>();
    
    return createElement(
        type === 'list' ? 'ul' : 'table',
        { ref: disable ? undefined : animationParent, ...otherProps }
    );
}

export default AnimatedList;