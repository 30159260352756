import { FC } from 'react';
import flags from '../../../utils/data/flags';
import FlagListCard from './FlagListCard';

type Props = {
    children?: any;
};

const FeatureFlagsPage: FC<Props> = ({ children }) => {        
    return (
        <>
            <div className="p-8">
                <div className="mb-6">
                    <div className="pre-heading">Configuratie</div>
                    <div className="heading">Feature flags</div>
                </div>
                <div>
                    <ul>
                        { flags.map((flag) => (
                            <FlagListCard flagId={ flag.id } />
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default FeatureFlagsPage;