import { FC, ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { User } from '../../../../types/verification';
import { Wallet } from '../../../../types/wallet';
import { Button, Icon } from '../../../basics';
import { TopupPopover, WalletPreviewScrollList } from '../../../elements';

type Props = {
    children?: ReactElement | ReactElement[];
};

const UserManagePage: FC<Props> = ({ children }) => {
    const [ showTopupPopup, setShowTopupPopup ] = useState(false);
    const [ selectedWallet, setSelectedWallet ] = useState<Wallet>();
    const { id } = useParams();
    const endpoints = useEndPoints();
    const { user } = useAuth();
    const { data: userData } = useAxiosBeta<User>(endpoints.user.byId(id as string));
    
    if (!userData) return null;
    
    return (
        <>
            <div className="pt-8 h-full flex flex-col">
                <div className="mb-6 flex-1">
                    <div className="px-8 mb-8">
                        <h3 className="pre-heading">Beheer</h3>
                        <h4 className="heading">{ userData.firstName } { userData.lastName }</h4>
                        <h5 className="text--main">{ userData.email }</h5>
                    </div>
                    <WalletPreviewScrollList
                        byUser={ id }
                        onWalletSelect={ wallet => setSelectedWallet(wallet)}
                    />
                </div>
                <div className="px-8 sticky bottom-0">
                    <Button 
                        theme="main"
                        onClick={() => setShowTopupPopup(true)}
                        disabled={ !selectedWallet }
                    >Topup</Button>
                </div>
            </div>
            { selectedWallet && (
                <TopupPopover 
                    active={ showTopupPopup }
                    wallet={ selectedWallet }
                    onClose={() => setShowTopupPopup(false)}
                />
            )}
        </>
    )
}

export default UserManagePage;