import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UseBaseAxios } from './useAxiosBeta.types';
import useAxiosReducer from './useAxiosReducer';
import { useEffectOnce } from '../useEffectOnce/useEffectOnce';
import { Error as ErrorType } from '../../../types/general';
import { AxiosContext } from './context/axiosContext';

const useBaseAxios: UseBaseAxios = <Data,>(initialEndpoint: string, initialConfig?: AxiosRequestConfig) => {
    const axiosConfigFromContext = useContext(AxiosContext);
    const [dataStates, dispatch] = useAxiosReducer<Data>();
    const isMounted = useRef(true);
    const controller = useRef<AbortController>(new AbortController());
    const signal = controller.current.signal;
    const axiosConfig = { ...axiosConfigFromContext, ...initialConfig, signal };
        
    const abort = useCallback(() => {
        if (isMounted.current) {
            controller.current.abort();
            dispatch({ type: 'REQUEST_ABORT' })
        }
    }, []);
    
    const getData = useCallback(
        async (
            endpoint?: string, 
            config?: AxiosRequestConfig
        ) => {
            dispatch({ type: 'REQUEST_INIT' });
            
            try {
                const res = (await axios(
                    endpoint || initialEndpoint, 
                    { ...axiosConfig, ...config })
                ) as AxiosResponse<Data>;
                
                if (isMounted.current) {
                    dispatch({ type: 'REQUEST_SUCCESS', payload: res.data });
                    return res.data;
                }
            } catch (e: ErrorType) {
                if (isMounted.current) {
                    dispatch({ type: 'REQUEST_FAILED', payload: e });
                    throw new Error(e);
                }
            }
        }, 
        [initialEndpoint, initialConfig]
    );
    
    useEffectOnce(() => {
        return () => {
            isMounted.current = false;
            abort();
        }
    })
    
    return [
        getData,
        {
            ...dataStates,
            refetch: getData,
            cancel: abort,
        }
    ]
}

export default useBaseAxios;