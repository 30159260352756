import classNames from 'classnames';
import { FC, ReactElement, useState, memo } from 'react';
import useFlags from '../../../states/hooks/useFlags/useFlags';
import useShareRouting from '../../../states/hooks/useShareRouting/useShareRouting';
import { User } from '../../../types/verification';
import { Icon, QR } from '../../basics';
import Popover from '../popover/Popover';

type Props = {
    user: User;
    single?: boolean;
    onClick?: () => void;
    isActive?: boolean;
};

const UserListItem: FC<Props> = memo(({ user, single, isActive, onClick }) => {
    const { flagById } = useFlags();
    const [{ generateRoute }] = useShareRouting();
    const [ showPopover, setShowPopover ] = useState(false);
    
    const qrValue = generateRoute('user', user.id);
    const showQRCode = flagById('qr_scanner_enabled')?.state;
    
    // overflow-hidden text-ellipsis max-w-[17ch] for email
            
    return (
        <>
            <div
                className={ classNames('flex items-center justify-between', !single && 'py-4 border-b group-last:border-b-0 border--themed')}
            >
                <div className="flex items-center shrink" onClick={ onClick }>
                    <Icon name="user" size="1.6rem" className="text--main mr-4 shrink-0" />
                    <div className="shrink-1">
                        <h3 className="text-lg leading-4 text--main">{ user.firstName }</h3>
                        <p className="text-stone-500 dark:text-stone-400">{ user.email }</p>
                    </div>
                </div>
                <div>
                    { isActive && (
                        <div>
                            <Icon name="check" size="1.6rem" />
                        </div>
                    )}
                    { showQRCode && (
                        <button
                            onClick={() => setShowPopover(true)}
                            className="ml-4 shrink-0"
                        >
                            <Icon name="qr-code" size="1.6rem" />
                        </button>
                    )}
                </div>
            </div>
            <Popover active={ showPopover } onClose={() => setShowPopover(false)}>
                <div className="mb-6">
                    <h3 className="popover__title text-center">{ user.firstName } { user.lastName }</h3>
                    <h4 className="popover__subtitle text-center">{ user.email }</h4>
                </div>
                <div className="mx-auto w-fit">  
                    <QR value={ qrValue } />
                </div>
            </Popover>
        </>
    )
})

export default UserListItem;