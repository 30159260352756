import { FC } from 'react';
import { ExspansionPane, ToggleInput } from '../../../../basics';
import { Input } from '../../../../elements';
import { useWatch } from 'react-hook-form';

interface Props {};

const CreditOverdraftFormSection: FC<Props> = () => {
    const overdraftAllowed = useWatch({ name: 'allowOverdraft' })
    return (
        <>
            <label className="flex items-center gap-3 mb-4">
                <ToggleInput name="allowOverdraft" />
                <div className="block text--main text-lg">Negatief crediet toestaan</div>
            </label>
            <ExspansionPane active={ overdraftAllowed }>
                <div className="mb-5">
                    <Input name="overdraftFee" type="number" label="Boete (%)" defaultValue={ 0 } min={ 0 } />
                    <div className="block text--secondary text-sm mt-2">0% = geen boete</div>
                </div>
            </ExspansionPane>
        </>
    )   
}

export default CreditOverdraftFormSection;