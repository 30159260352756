import { FC } from 'react';
import { Link } from 'react-router-dom';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { CreditProvider } from '../../../../types/wallet';
import { Button, Icon } from '../../../basics';
import { AxiosDataList } from '../../../elements';

interface Props {};

const CreditProviderManagePage: FC<Props> = () => {
    const endpoints = useEndPoints();
    
    return (
        <div className="px-8 pt-8 h-full flex flex-col">
            <div className="mb-6">
                <div className="pre-heading">Beheer</div>
                <div className="heading">Credit providers</div>
            </div>
            <div className="flex-1">
                <AxiosDataList url={ endpoints.creditProviders.all }>
                    {({ data }) => data?.map((creditProvider: CreditProvider) => (
                        <li className="list__item text-lg leading-4 text--main">
                            <Link to={ creditProvider.id } className="flex items-center gap-4">
                                <Icon name="wallet" />
                                <div className="flex-1 flex justify-between">
                                    <span>{ creditProvider.label }</span>
                                    { creditProvider.public && <span className="label text-sm text--secondary">Publiek</span>}
                                </div>
                            </Link>
                        </li>
                    ))}
                </AxiosDataList>
            </div>
            <Link to="./new">
                <Button>Provider toevoegen</Button>
            </Link>
        </div>
    )
}

export default CreditProviderManagePage;