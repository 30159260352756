import dayjs from 'dayjs';
import { FC } from 'react';
import { Transaction } from '../../../types/wallet';
import Pricfy from '../../basics/pricify/Pricify';

type Props = {
    transaction: Transaction;
};

const TransactionListItemFromUser: FC<Props> = ({ transaction }) => {
    const transactionTotal = transaction.items.reduce((acc, item) => {
        const subtitle = item.amount * item.product.price;
        return acc + subtitle;
    }, 0);
    
    return (
        <li className="flex items-center justify-between py-4 border-b border--themed last:border-b-0">
            <div>
                <h3 className="text--main text-lg">{ transaction.user.firstName }</h3>
                <p className="text--secondary text-sm">{ dayjs(transaction.createdAt).fromNow() }</p>
            </div>
            <h4 className="text--main"><Pricfy>{ transactionTotal }</Pricfy></h4>
        </li>
    )
}

export default TransactionListItemFromUser;