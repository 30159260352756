import { FC, ReactElement } from 'react';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { Transaction } from '../../../../types/wallet';
import { Icon } from '../../../basics';
import { AxiosDataList } from '../../../elements';
import TransactionListItem from '../../../elements/transactions/TransactionListItem';
import TransactionListItemFromUser from '../../../elements/transactions/TransactionListItemFromUser';

type Props = {
    onReferClick: () => void
};

const StatsOverviewPage: FC<Props> = ({ onReferClick }) => {
    const endpoints = useEndPoints();
    
    return (
        <div className="h-full">
            <button onClick={ onReferClick } className="w-full flex items-center justify-between text-left border-b px-8 pb-6 mb-6">
                <div className="mr-4">
                    <h3 className="text-lg text--main">Bekijk alle uitgaven!</h3>
                    <p className="text-sm text--secondary">Je kan wel al de transacties<br />van alle gebruikers bekijken!</p>
                </div>
                <Icon name="arrow-right" size="1.6rem" className="text--secondary" />
            </button>
            
            <div className="mb-4 px-8">
                <h3 className="text--main text-lg">Recente uitgaven</h3>
                <AxiosDataList url={ endpoints.transactions.all + '?limit=3' }>
                    {({ data }) => data?.map((transaction: Transaction) => (
                        <TransactionListItemFromUser transaction={ transaction } />
                    ))}
                </AxiosDataList>
            </div>
            <hr className="mb-6" />
            <div className="px-8">
                <div className="p-4 bg-stone-100 text--secondary rounded-lg text-sm">
                    Hier bekijk je binnenkort een overzicht van alle statistieken
                </div>
            </div>
        </div>
    )
}

export default StatsOverviewPage;