import classNames from 'classnames';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {};

const PageLayout: FC<Props> = ({ children, className, ...otherProps }) => {
    return (
        <div className={classNames(
            'p-8 h-full flex flex-col',
            className
        )}>
            { children }
        </div>
    )
}

export default PageLayout;