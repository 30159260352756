import { FC, ReactElement } from 'react';

type Props = {
    children?: ReactElement | ReactElement[];
};

const StatsTopupsPage: FC<Props> = ({ children }) => {
    return (
        <div className="h-full px-8">
            <div className="p-4 bg-stone-100 text--secondary rounded-lg text-sm">
                Hier bekijk je bekijk je binnenkort alle topups
            </div>
        </div>
    )
}

export default StatsTopupsPage;