import { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import clientPackageInfo from '../../../../package.json';
import useLazyAxios from '../../../states/hooks/useAxiosBeta/useLazyAxios';
import useEndPoints from '../../../states/hooks/useEndpoints/useEndpoints';
import { AnimatedList, ExspansionPane, Icon } from '../../basics';
import { Loader } from '../../elements';

type CardProps = {
    dependency: any;
}

const PackageInfoCard: FC<CardProps> = ({ dependency }) => {
    const endpoints = useEndPoints();
    const [ showDetail, setShowDetail ] = useState(false);
    const version = useMemo(() => dependency.version.replace('^', ''), [dependency]);
    const [ getData, { data, loading }] = useLazyAxios<any>(endpoints.npm.packageInfo(dependency.name, version));
    
    const handleToggle = useCallback(() => {
        setShowDetail(s => !s);
        if (!data) {
            getData();
        }
    }, [ data ])
    
    return (
        <li className="border-b last:border-b-0 border--themed py-4">
            <div className="flex justify-between items-center" onClick={ handleToggle }>
                <div>
                    <h3 className="text--main">{dependency.name }</h3>
                    <h4 className="text--secondary">versie { version }</h4>
                </div>
                { dependency.dev && (
                    <div className="flex flex-col items-center">
                        <Icon name="code" className="text--main" />
                        <span className="label text-sm text--main">dev</span>
                    </div>
                )}
            </div>
            
            <ExspansionPane active={ showDetail }>
                { loading && (<Loader size="1.6rem" /> )}
                { data && (<div className="mt-2">
                    <div className="mb-3">
                        <h4 className="text-sm text--main">Gebruik</h4>
                        <p className="text-sm text--secondary">{ dependency.dev ? 'Tijdens development' : 'In browser' }</p>
                    </div>
                    <div className="mb-3">
                        <h4 className="text-sm text--main">Over</h4>
                        <p className="text-sm text--secondary">{ data.description }</p>
                    </div>
                    <div className="mb-3">
                        <h4 className="text-sm text--main">Licentie</h4>
                        <p className="text-sm text--secondary">{ data.license }</p>
                    </div>
                    <div>
                        <h4 className="text-sm text--main">Meer info</h4>
                        <a href={ data.homepage } target="_blank" className="block text-sm text--secondary underline underline-offset-3 whitespace-nowrap overflow-hidden text-ellipsis max-w-full">{ data.homepage }</a>
                    </div>
                </div>)}
            </ExspansionPane>
        </li>
    )
}

type Props = {
    children?: ReactElement | ReactElement[];
};

const AboutPackagesPage: FC<Props> = ({ children }) => {
    const deps = Object.entries(clientPackageInfo.dependencies).map(([name, version]) => ({ name, version, dev: false }));
    const devDeps = Object.entries(clientPackageInfo.devDependencies).map(([name, version]) => ({ name, version, dev: true }));
    
    const depsList = [ ...deps, ...devDeps ].sort((a, b) => a.name.localeCompare(b.name));
    
    return (
        <div className="p-8">
            <div className="mb-6">
                <div className="pre-heading">Applicatie</div>
                <div className="heading">Software van derden</div>
                <p className="text-sm mt-4 text--secondary">
                    Deze applicatie werd ontwikkeld met de hulp van enkele geweldige tools, geschreven door een fantastische community van nerds. 
                    Alhoewel een shoutout niet vereist is door de aard van open-source software, doen we het toch.
                </p>
            </div>
            <AnimatedList>
                { depsList.map((dependency, index) => (
                    <PackageInfoCard key={ index } dependency={ dependency } />
                ))}
            </AnimatedList>
        </div>
    )
}

export default AboutPackagesPage;