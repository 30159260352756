import axios from "axios";
import { useCallback, useContext, useMemo } from "react"
import { ID } from "../../../types/general";
import { AuthenticatedUser, LoginCredentials, Session, User } from "../../../types/verification";
import { authContext } from "../../contexts/AuthContext/AuthContext"
import { AuthFunctions, UseAuth } from "./useAuth.types";
import { Wallet } from "../../../types/wallet";
import useSessionStore from "../../stores/useSessionStore";
import useEndPoints from "../useEndpoints/useEndpoints";

const useAuth: UseAuth = () => {
    const endpoints = useEndPoints();
    // const navigate = useNavigate();
    const { stored: sessions, sessionById, registerSession, updateSession, updateSessionAccess } = useSessionStore();
    const { authState, dispatch } = useContext(authContext);
    
    const getSessionByUserId = (id: string): Session | undefined => {
        return sessions.find(session => session.user.id === id);
    }
    
    const sortedSessions = useMemo(() => {
        return sessions.sort((a, b) => {
            return new Date(b.lastAccess).getTime() - new Date(a.lastAccess).getTime()
        });
    }, [sessions])
    
    const latestSession = useMemo(() => {
        return sortedSessions?.[0];
    }, [])
    
    const storeNewSession = (user: User) => {
        registerSession(user);
    }
    
    const login: AuthFunctions['login'] = (credentials: LoginCredentials) => {
        dispatch({ type: 'AUTH_INIT' });
        
        const request = axios(endpoints.auth.login, {
            method: 'POST',
            data: credentials
        });
        
        request.then((response) => {
            const data = response.data as AuthenticatedUser;
            const user = data.user;
            const sessionSaved = getSessionByUserId(user.id);
            const cachedWalletId = window.localStorage.getItem('selectedWallet');
            
            console.log(sessionSaved)
            
            if (sessionSaved) {
                updateSessionAccess(sessionSaved.id);
            } else {
                console.log('STORED SESSION')
                storeNewSession(user);
            }
            
            dispatch({ type: 'AUTH_SUCCESS', payload: response.data })
            
            if (cachedWalletId) {
                const selectedWallet = data.wallets.find(w => w.id === cachedWalletId)
                                
                if (selectedWallet) {
                    dispatch({ type: 'WALLET_SELECT', payload: selectedWallet })
                }
            }
        });
        request.catch((error: any) => dispatch({ type: 'AUTH_FAILED', payload: error }));
    }
    
    const refreshUser: AuthFunctions['refreshUser'] = () => {
        if (authState?.user) {
            const request = axios((process.env.REACT_APP_API_URL || 'http://localhost:4000') + '/user', {
                headers: {
                    'Authorization': 'Bearer ' + authState.user.token
                }
            });
            
            request.then((response) => {
                const user = response.data.user;  
                const sessionSaved = getSessionByUserId(user.email);
                
                if (sessionSaved) {
                    updateSessionAccess(sessionSaved.id);
                } else {
                    storeNewSession(user);
                }
                
                dispatch({ type: 'USER_REFRESH', payload: response.data })
            });
        }
    }
    
    const loginRetry = () => {
        dispatch({ type: 'AUTH_RETRY' });
    }
    
    const selectWallet = useCallback((wallet: ID) => {
        window.localStorage.setItem('selectedWallet', wallet);
        const foundWallet = authState.user?.wallets.find(w => w.id === wallet) as Wallet;
        
        dispatch({
            type: 'WALLET_SELECT',
            payload: foundWallet
        })
    }, [authState.user])
    
    const logout: AuthFunctions['logout'] = (goTo?: string) => {
        dispatch({
            type: 'AUTH_INITIAL',
        })
        // navigate(goTo || '/session');
    }
    
    return {
        ...authState,
        sessions: sortedSessions,
        latestSession,
        getSessionById: sessionById,
        getSessionByUserId,
        login,
        loginRetry,
        logout,
        refreshUser,
        selectWallet,
    };
}

export default useAuth;