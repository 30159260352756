import { Loader, Router } from '../../components/elements';
import AuthContextProvider from '../../states/contexts/AuthContext/AuthContext';
import CartContextProvider from '../../states/contexts/CartContext/CartContext';
import { ToastContainer } from 'react-toastify';
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import 'react-toastify/dist/ReactToastify.css';
import AxiosContextProvider from '../../states/hooks/useAxiosBeta/context/axiosContext';
import { preflightRequest } from '../../utils/funcs/requests';

const queryClient = new QueryClient();

const AppWrappedContent = () => {
  const { isLoading: preflightLoading, error: preflightError } = useQuery<any, any>('serverPreflight', preflightRequest)
  
  if (preflightLoading || preflightError) return (
    <div className="h-full flex flex-col">
      <div className="flex-1 flex flex-col items-center justify-center">
        <img src="/logoBeta521.png" className="w-28 mb-4" />
        { preflightLoading && <Loader/> }
        { preflightError && <>
          <p className="text--main">We konden de app niet laden</p>
          <p className="text--secondary text-sm">Kom later terug en probeer opnieuw</p>
        </>}
      </div>
      <p className="text-sm text-center text--secondary label p-4">Design & Dev door <a href="https://www.jung.gent" target="_blank" className="underline underline-offset-2 decoration-stone-300 dark:decoration-stone-700">JUNG.GENT</a></p>
    </div>
  )
    
  return (
      <AuthContextProvider>
        <AxiosContextProvider>
          <CartContextProvider>
            {/* { app.installed && process.env.NODE_ENV === 'development' ? 
              <Router /> :
              <PwaInstallPage />
            } */}
            <Router />
            <ToastContainer
              position="bottom-center"
              autoClose={ 5000 }
              hideProgressBar={ true }
              newestOnTop={ false }
              rtl={ false }
              draggable
            />
          </CartContextProvider>
        </AxiosContextProvider>  
      </AuthContextProvider>
  );
}


const App = () => {    
  return (
    <QueryClientProvider client={queryClient}>
      <AppWrappedContent />
    </QueryClientProvider>
  );
}

export default App;

