import { AxiosRequestConfig } from "axios";
import { createContext, FC, PropsWithChildren } from "react";
import useAuth from "../../useAuth/useAuth";

const initialValue = {};
const AxiosContext = createContext<AxiosRequestConfig>(initialValue);

type Props = PropsWithChildren<{}>;

const AxiosContextProvider: FC<Props> = ({ children }) => {
    const { user: authUser } = useAuth();
    const axiosInitialConfig = {
        headers: {
            Authorization: `Bearer ${authUser?.token}`,
        }
    }
    
    return (
        <AxiosContext.Provider value={ axiosInitialConfig }>
            { children }
        </AxiosContext.Provider>
    )
}

export default AxiosContextProvider;
export { AxiosContext };