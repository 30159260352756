import { FeatureFlag } from "../../types/general";

const flags: FeatureFlag[] = [
    {
        label: 'Geavanceerde modus',
        id: 'app_mode',
        description: 'Extra functies inschakelen',
        state: false,
        reloadRequired: false,
        beta: false
    },
    {
        label: 'Developer modus',
        id: 'dev_mode',
        description: 'Optimaliseer applicatieomgeving voor ontwikkelaars.',
        state: false,
        reloadRequired: true,
        beta: true
    },
    {
        label: 'Checkout overzicht',
        id: 'checkout_overview_visible',
        description: 'Toon checkout overzicht.',
        state: false,
        reloadRequired: false,
        beta: false
    },
    {
        label: 'Desktop support',
        id: 'desktop_support',
        description: 'Maak gebruik van de app ook mogelijk op desktop in een experimentele omgeving.',
        state: false,
        reloadRequired: false,
        beta: true
    },
    {
        label: 'Donkere modus',
        id: 'dark_mode',
        description: 'Een donkere versie van de applicatie. Mogelijks werder nog niet alle elementen gewijzigd.',
        state: false,
        reloadRequired: false,
        beta: true
    },
    {
        label: 'Popup › product toegevoegd',
        id: 'prod_popup',
        description: 'Toon een popup wanneer een product toegevoegd wordt door op desbetreffende knop te klikken.',
        state: true,
        reloadRequired: false,
        beta: false
    },
    {
        label: 'Haptische feedback',
        id: 'haptic',
        description: 'Genereer vibraties bij het uitvoeren van bepaalde acties. Bijvoorbeeld het aanklikken van uitgeschakelde knop.',
        state: true,
        reloadRequired: false,
        beta: false
    },
    {
        label: 'Automatisch bijwerken',
        id: 'auto_data_refresh',
        description: 'Data wordt bij een bepaalde interval automatisch bijgewerkt.',
        state: true,
        reloadRequired: false,
        beta: true
    },
    {
        label: 'QR-code scanner',
        id: 'qr_scanner_enabled',
        description: 'Integreer een QR-scanner in de app voor het eenvoudig uitwisselen van informatie.',
        state: false,
        reloadRequired: false,
        beta: true
    },
    {
        label: 'Forceer in-browser',
        id: 'force_in_browser',
        description: 'Omzeil de doorverwijzing naar de installatie van de applicatie. Dit kan ongewenste gevolgen hebben!',
        state: false,
        reloadRequired: false,
        beta: true
    },
]

export default flags;