import { FC, useState } from 'react';
import useCart from '../../../../states/hooks/useCart/useCart';
import PurchaseProofPopover from '../PurchaseProofPopover';
import { ID } from '../../../../types/general';
import Checkout from './Checkout/Checkout';
import usePopover from '../../../../states/hooks/usePopover/usePopover';
import PurchaseConfirmPopover from './PurchaseConfirmPopover/PurchaseConfirmPopover';
import ProductList from './ProductList/ProductList';
import { FastCheckout } from '../../../elements';

type Props = {
    drivethru?: boolean;
};

const ProductsListPage: FC<Props> = ({ drivethru }) => {
    const [ purchaseProofId, setPurchaseProofId ] = useState<ID | undefined>()
    const [ confirmPopoverController, { toggle: toggleConfirmPopover }] = usePopover();
    const { startPurchase } = useCart();
    
    const handleCheckout = () => toggleConfirmPopover(true);
    
    const handlePurchaseConfirm = async () => {
        const purchase = await startPurchase();
        toggleConfirmPopover(false);
        setPurchaseProofId(purchase.data.id)
    }
            
    return (
        <div className="flex h-full flex-col">
            <div className="p-8 flex-1">
                <div className="mb-6">
                    <div className="pre-heading">Bar</div>
                    <div className="heading">Overzicht</div>
                </div>
                
                <ProductList />
            </div>
            
            { drivethru && <FastCheckout />}
            
            <Checkout onCheckout={ handleCheckout } />
            <PurchaseConfirmPopover controller={ confirmPopoverController } onPurchase={ handlePurchaseConfirm } />
            <PurchaseProofPopover
                transactionId={ purchaseProofId } 
                onClose={() => setPurchaseProofId(undefined)}
            />
        </div>
    )
}

export default ProductsListPage;