import create from 'zustand';
import { persist } from 'zustand/middleware';
import { ID } from '../../../types/general';
import defaultFlags from '../../../utils/data/flags';
import UseFlags from './useFlags.types';

const persistenceConfig = {
    name: 'flags',
    getStore: () => window.localStorage
}

const useFlags = create(
    persist<UseFlags>(
        (set, get) => ({
            flags: [],
            betaFlags: {},
            
            checkStorageState: (flagId: ID) => {
                const storedFlags = get().betaFlags
                return storedFlags[flagId];
            },
            
            
            // support beta
            toggleFlag: (flagId: ID, newState: boolean) => {                
                set((store) => {       
                    const updated = {
                        ...store.betaFlags,
                        [flagId]: newState
                    }
                                                            
                    return {
                        betaFlags: updated
                    }
                })
            },
            flagByIdBeta: (flagId: ID) => {
                const storedFlags = get().betaFlags;
                const flagData = defaultFlags.find((flag) => flag.id === flagId);
                                
                if (!flagData) return undefined;
                return {
                    ...flagData,
                    state: storedFlags[flagId] || flagData.state
                }
            },
            
            enable: (flag: ID) => {
                set((state) => {
                    const update = state.flags.map(f => {
                        if (f.id === flag) {
                            return {
                                ...f,
                                state: true
                            }
                        } else {
                            return f
                        }
                    })
                    
                    return {
                        flags: update
                    }
                })
            },
            
            disable: (flag: ID) => {
                set((state) => {
                    const flags = state.flags;
                    
                    const update = flags.map(f => {
                        if (f.id === flag) {
                            return {
                                ...f,
                                state: false
                            }
                        } else {
                            return f
                        }
                    })
                    
                    return {
                        flags: update
                    }
                })
            },
            
            flagById: (id: string) => {
                return get().flags.find(f => f.id === id) || defaultFlags.find(f => f.id === id)
            },
        }),
        persistenceConfig
    )
);

export default useFlags;