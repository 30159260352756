import { FC, ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Icon } from '../../basics';
import PageLayout from '../../layouts/PageLayout/PageLayout';

type Props = {
    children?: ReactElement | ReactElement[];
};

const NotFoundPage: FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    
    return (
        <PageLayout className="relative items-center justify-center">
            <Icon name="compass-3" size="2.5rem" className="mb-2 text--secondary" />
            <h2 className="pre-heading text-center">Oeps, foutje</h2>
            <h3 className="heading text-center">Niet beschikbaar</h3>
                
            <div className="mt-6 mx-auto flex items-center gap-4">
                <Button icon="arrow-left" iconPlacement="start" fitWidth small theme="simple" onClick={() => navigate(-1)}>Terug</Button>
                <Link to="/">
                    <Button small theme="secondary">Startpagina</Button>
                </Link>
            </div>
        </PageLayout>
    )
}

export default NotFoundPage;