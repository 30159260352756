import { useLayoutEffect, useMemo, useRef } from "react";
import { BeforeInstallPromptEvent } from "../../../types/global";
import useAxiosBeta from "../useAxiosBeta/useAxiosBeta";
import { useEffectOnce } from "../useEffectOnce/useEffectOnce";
import useEndPoints from "../useEndpoints/useEndpoints";
import { UseApp } from "./useApp.types";

export const useApp: UseApp = () => {
    const endpoints = useEndPoints();
    const pwaInstallable = useRef<BeforeInstallPromptEvent>();
    const { data } = useAxiosBeta<any>(endpoints.app.version);
    
    const isUptodate = useMemo(() => (data?.update?.sha === process.env['REACT_APP_VERCEL_GIT_COMMIT_SHA'] || null), [data]);
    const updateAvailable = !isUptodate;
    const updateInfo = data;
    
    useLayoutEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            pwaInstallable.current = e;
        });
    })
    
    const add2Screen = async () => {
        try {
            if (pwaInstallable.current) {
                await pwaInstallable.current.prompt();
                pwaInstallable.current.userChoice.then(() => {
                    pwaInstallable.current = undefined;
                })
            }   
        } catch (error) {
            console.log(error)
        }
    }
    
    const installed = window.matchMedia('(display-mode: standalone)').matches
    
    return {
        installed,
        isUptodate,
        updateAvailable,
        updateInfo,
        add2Screen
    }
}