import { FC, ReactElement } from 'react';
import { ScrollableCardsProps, ScrollableCardsOptions } from './ScrollableCards.types';
import Wrapper from './Wrapper';
import styles from './ScrollableCards.module.scss';

const defaultOptions: ScrollableCardsOptions = {
    perView: 5
}

const ScrollableCards: FC<ScrollableCardsProps> = ({ children, ...otherProps }) => {
    const { perView } = { ...defaultOptions, ...otherProps};
    
    
    return (
        <Wrapper className={ otherProps.className }>
            { children }
        </Wrapper>
    )
}

export default ScrollableCards;