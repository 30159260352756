import { useEffect } from "react";
import { ID } from "../../../types/general";
import useAuth from "../useAuth/useAuth";
import useLazyAxios from "../useAxiosBeta/useLazyAxios";
import { PurchaseVerification } from "../useCart/useCart.types";
import useEndPoints from "../useEndpoints/useEndpoints";

const usePurchaseVerification = (amount: number, walletId: ID, items?: any[]) => {
    const endpoints = useEndPoints();
    const { user } = useAuth();
    const [ verifyPurchase, { data: verificationResult, ...otherStates }] = useLazyAxios<PurchaseVerification>(endpoints.purchase.verify);
        
    useEffect(() => {
        verifyPurchase(undefined, endpoints.purchase.verify, {
            headers: {
                Authorization: 'Bearer' + user?.token,
            },
            params: {
                amount,
                wallet: walletId
            }
        });
    }, [ amount, walletId, items ])
        
    return {
        result: verificationResult,
        ...otherStates
    };
}

export default usePurchaseVerification;