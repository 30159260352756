import classNames from 'classnames';
import { FC } from 'react';
import useEndPoints from '../../../states/hooks/useEndpoints/useEndpoints';
import { ID } from '../../../types/general';
import { Wallet } from '../../../types/wallet';
import { filterDisabledWalletsOut } from '../../../utils/funcs/algorithms/filters';
import { Icon, Pricify } from '../../basics';
import AxiosDataList from '../AxiosDataList/AxiosDataList';

interface Props {
    onSelect?: (walletId: ID) => void;
    showCheck: boolean;
    currentSelected?: ID;
};

const WalletSelector: FC<Props> = ({ currentSelected, onSelect, showCheck }) => {
    const endpoints = useEndPoints();
    
    const handleWalletSelect = (walletId: ID) => {
        onSelect?.(walletId);
    }
    
    return (
        <AxiosDataList url={ endpoints.user.wallets }>
            {({ data }) => data?.filter(filterDisabledWalletsOut).map((wallet: Wallet) => (
                    <li
                        key={ wallet.id } 
                        onClick={() => handleWalletSelect(wallet.id)}
                        className="flex items-center justify-between border-b last:border-b-0 border--themed py-4 text-lg"
                    >
                        <div className="flex justify-between items-baseline w-full">  
                            {  wallet.provider.label ? <span className="text--main">{ wallet.provider.label }</span> : <span className="text--secondary">Geen naam</span> }
                            <span className={classNames((!wallet.provider.allowOverdraft && wallet.balance <= 0) ? 'text-red-500' : 'text--secondary' )}><Pricify>{ wallet.balance }</Pricify></span>
                        </div>
                        { !!currentSelected && showCheck && (
                            <Icon name="check" className={ classNames(
                                'ml-4 text--main',
                                currentSelected === wallet.id ? 'opacity-100' : 'opacity-0'
                            )} />
                        )}
                    </li>
                )
            )}
        </AxiosDataList>
    )
}

export default WalletSelector;