import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import usePopover from '../../../../states/hooks/usePopover/usePopover';
import { AuthnSession } from '../../../../types/verification';
import { calendarFormat } from '../../../../utils/data/dayjs';
import { Button, Icon } from '../../../basics';
import { AxiosDataList, Popover } from '../../../elements';
import useSessionStore from '../../../../states/stores/useSessionStore';
import useAuth from '../../../../states/hooks/useAuth/useAuth';

interface SessionDevicePopoverProps {
    session: AuthnSession
}

// const fakeCurrentSession = '638fcfa530dd4708998c183a';

const SessionDevicePopover: FC<SessionDevicePopoverProps> = ({ session }) => {
    const [ controller, { open }] = usePopover();
    const { stored } = useSessionStore();
    const { user: currentUser } = useAuth();
    
    const deviceTitle = useMemo(() => {
        if (session.device.device.model && session.device.device.vendor) {
            return `${ session.device.device.vendor } ${ session.device.device.model }`
        } else {
            return `${ session.device.browser.name } op ${ session.device.os.name }`
        }
    }, [ session.device.device ]);
        
    const isCurrentSession = useMemo(() => {
        const storedSession = stored.find((session) => session.user.id === currentUser?.user.id);
        return storedSession?.id === session.id
    }, [stored, session]);
        
    const lastLogin = useMemo(() => dayjs(session.updatedAt).calendar(dayjs(), calendarFormat), [])
    
    // const lastLogin = useMemo(() => dayjs(session.updatedAt).format('DD MMM'), [ session.updatedAt ]);
    const firstLogin = useMemo(() => dayjs(session.createdAt).calendar(dayjs(), calendarFormat), [ session.createdAt ]);
    
    const propertiesList = useMemo(() => {
        return [
            { label: 'Eerste login', value: firstLogin },
            { label: 'Browser', value: session.device.browser.name },
            { label: 'Besturingssysteem', value: session.device.os.name },
            { label: 'Apparaat', value: deviceTitle },
        ]
    }, [ session.device, deviceTitle, firstLogin ]);
    
    return (
        <>
            <li className="list__item flex items-center justify-between gap-6" onClick={() => open()}>
                <div className="flex items-center gap-4">
                    <Icon name="macbook" />
                    <div>
                        <h4 className="text--main">{ deviceTitle }</h4>
                        <p className="text--secondary text-sm">{ isCurrentSession ? 'Dit apparaat' : lastLogin }</p>
                    </div>
                </div>
                { isCurrentSession && <Icon name="checkbox-circle" />}
            </li>
            <Popover { ...controller }>
                <div className="mb-4">
                    <h3 className="popover__title">{ deviceTitle }</h3>
                    <h4 className="popover__subtitle">{ isCurrentSession ? 'Dit apparaat' : lastLogin }</h4>
                </div>
                <ul>
                    { propertiesList.map((property) => (
                        <li className="list__item flex justify-between">
                            <span>{ property.label }</span><span>{ property.value }</span>
                        </li>
                    ))}
                </ul>
                {/* { !isCurrentSession && (
                    <div className="mt-6">
                        <div className="p-4 bg-stone-100 text--secondary rounded-lg text-sm">
                            Meld je af als je dit apparaat niet herkent
                        </div>
                        <Button theme="secondary" className="mt-4">Afmelden</Button>
                    </div>
                )} */}
            </Popover>
        </>
    )
}

interface Props {};

const UserSessionsPage: FC<Props> = () => {
    const endpoints = useEndPoints();
    
    const sortedSessions = (data: AuthnSession[]) => data.sort((a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf());

    return (
        <div className="p-8">
            <div className="mb-6">
                <div className="pre-heading">Account</div>
                <div className="heading">Apparaten</div>
            </div>
            <div className="p-4 bg-stone-100 text--secondary rounded-lg text-sm">
                Dit zijn alle apparaten waar je recent op aangemeld bent
            </div>
            
            <AxiosDataList url={ endpoints.user.sessions }>
                {({ data }) => data?.map((session: AuthnSession) => (
                    <SessionDevicePopover key={ session.id } session={ session } />
                ))}
            </AxiosDataList>
        </div>
    )
}

export default UserSessionsPage;