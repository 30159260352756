import classNames from 'classnames';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../../states/hooks/useAuth/useAuth';
import useAxiosBeta from '../../../../states/hooks/useAxiosBeta/useAxiosBeta';
import useLazyAxios from '../../../../states/hooks/useAxiosBeta/useLazyAxios';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { CreditProvider } from '../../../../types/wallet';
import { AnimatedList, Button, ExspansionPane, Icon, Toggle, ToggleInput } from '../../../basics';
import { Form, Input, Loader, Popover } from '../../../elements';
import { PopoverProps } from '../../../elements/popover/Popover';
import CreditOverdraftFormSection from '../../managePage/CreditProviderManagePages/components/CreditOverdraftFormSection';
import PhotoSelectorPopover from '../../../elements/PhotoSelectorPopover/PhotoSelectorPopover';

type Props = {
};

const WalletEditPage: FC<Props> = () => {
    const navigate = useNavigate();
    const [ showImageSelector, setShowImageSelector ] = useState(false);
    const [ coverImage, setCoverImage ] = useState<any>(null)
    const { id: walletId } = useParams();
    const endpoints = useEndPoints();
    const { data: creditProvider } = useAxiosBeta<CreditProvider>(endpoints.creditProviders.byId(walletId || ''));
    const [ saveCreditProvider, savedCreditProvider ] = useLazyAxios<CreditProvider>(endpoints.creditProviders.byId(walletId || ''), {
        method: 'PATCH',
    });
    
    const handleImageSelector = (photo: string) => {
        setShowImageSelector(false);
        setCoverImage(photo);
    }
    
    const handleSaving = async (data: any) => {
        await saveCreditProvider({
            ...data,
            coverImage,
        });
        navigate('..')
    }
    
    useEffect(() => {
        if (creditProvider?.coverImage) {
            setCoverImage(creditProvider.coverImage);
        }
    }, [creditProvider])
        
    if (!creditProvider) return <Loader />
    return (
        <>
            <div className="pt-8 px-8 h-full flex flex-col">
                <div className="mb-6">
                    <h3 className="pre-heading">Wallet</h3>
                    <h2 className="heading">Wallet bewerken</h2>
                </div>
            
                <Form 
                    defaultValues={{
                        label: creditProvider.label,
                        public: creditProvider.public,
                        enabled: creditProvider.enabled,
                        allowOverdraft: creditProvider.allowOverdraft,
                        overdraftFee: creditProvider.overdraftFee,
                    }}
                    className="flex-1 flex flex-col"
                    onSubmit={ handleSaving }
                >
                    <div className="flex-1">
                        <Input name="label" label="Label" className="mb-5" />
                        {/* <Input name="public" label="Publiek maken?" type="checkbox" className="mb-4" /> */}
                        <label className="flex items-center gap-3 mb-3">
                            <ToggleInput name="enabled" />
                            <div className="block text--main text-lg">Inschakelen</div>
                        </label>
                        <label className="flex items-center gap-3 mb-3">
                            <ToggleInput name="public" />
                            <div className="block text--main text-lg">Maak publiek</div>
                        </label>
                        <CreditOverdraftFormSection />
                        {/* <label className="flex items-center gap-3 mb-4">
                            <ToggleInput name="neg_cred_fee" />
                            <div className="block text--main text-lg"></div>
                        </label> */}
                        <div>
                            <div className="block text--main text-lg mb-1">Cover afbeelding</div>
                            { !coverImage ? 
                                <Button type="button" theme="secondary" small onClick={() => setShowImageSelector(true)} className="mt-4">Kies afbeelding</Button> : 
                                <>
                                    <div className="overflow-hidden ratio-card rounded-xl">
                                        <img src={ endpoints.unsplash.imageById(coverImage) } className="bg-stone-300 w-full object-cover blur-sm" />
                                    </div>
                                    <Button type="button" theme="secondary" small onClick={() => setShowImageSelector(true)} className="mt-4">Wijzig afbeelding</Button>
                                </>
                            }
                        </div>
                    </div>
                
                    <Button type="submit" loading={ savedCreditProvider.loading }>Opslaan</Button>
                </Form>
            </div>
            <PhotoSelectorPopover 
                active={ showImageSelector }
                onClose={() => setShowImageSelector(false)}
                onSelect={ handleImageSelector }
            />
        </>
    )
}

export default WalletEditPage;