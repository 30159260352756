import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ExspansionPane } from '../../../../components/basics';
import { AxiosDataList, Form, Input } from '../../../../components/elements';
import useEndPoints from '../../../../states/hooks/useEndpoints/useEndpoints';
import { UserRole } from '../../../../types/verification';

interface Props {};

const RolesOverviewPage: FC<Props> = () => {
    const params = useParams<any>();
    const navigate = useNavigate();
    const endpoints = useEndPoints();
    
    return (
        <div className="container mx-auto py-12">
            <div className="mb-8">
                <h3 className="text-2xl font-semibold text--main">Gebruikersrollen</h3>
            </div>
            <AxiosDataList url={ endpoints.roles.all } disableAnimated className="w-full">
                {({ data, refetch }) => <>
                    <div className="grid grid-cols-12">
                        <div className="col-span-2 text-left font-bold">Label</div>
                        <div className="col-span-10 text-left font-bold">Beschrijving</div>
                    </div>
                    { data?.map((role: UserRole) => (
                        <div key={ role.id } className="border-b border-stone-300">
                            <div 
                                className="cursor-pointer hover:bg-stone-100 grid grid-cols-12 py-4"
                                onClick={() => navigate('../' + role.id, { relative: 'path' })}
                            >
                                <div className="col-span-2">{ role.name }</div>
                                <div className="col-span-10">{ role.description }</div>
                            </div>
                            <ExspansionPane active={ params.id === role.id }>
                                <Form className="pb-4" defaultValues={{ name: role.name, description: role.description }}>
                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-6">
                                            <Input label="Naam" name="name" />
                                        </div>
                                        <div className="col-span-6">
                                            <Input label="Omschrijving" name="description" />
                                        </div>
                                    </div>
                                    <Button fitWidth className="ml-auto mt-4" onClick={() => refetch()}>Wijzigingen opslaan</Button>
                                </Form>
                            </ExspansionPane>
                        </div>
                    ))}
                </>}
            </AxiosDataList>
        </div>
    )
}

export default RolesOverviewPage;