import { createContext, Dispatch, FC, PropsWithChildren, useEffect, useMemo } from "react";
import { Action, PurchaseVerification } from "../../hooks/useCart/useCart.types";
import { initialState } from "../../hooks/useCart/useCartReducer";
import useCartReducer from "../../hooks/useCart/useCartReducer";
import useAuth from "../../hooks/useAuth/useAuth";
import useLazyAxios from "../../hooks/useAxiosBeta/useLazyAxios";
import useEndPoints from "../../hooks/useEndpoints/useEndpoints";
import usePurchaseVerification from "../../hooks/usePurchaseVerification/usePurchaseVerification";

const stub: Dispatch<Action> = () => {
    throw new Error('You forgot to wrap your component in <CartContextProvider>.')
}

const initialContext = { cartState: initialState, dispatch: stub }
const cartContext = createContext(initialContext);
const ContextProvider = cartContext.Provider;

interface Props extends PropsWithChildren<{}> {}

const CartContextProvider: FC<Props> = ({ children }) => {
    const { selectedWallet } = useAuth()
    const [ cartState, dispatch ] = useCartReducer();
    
    const total = useMemo(() => {
        return cartState.items.reduce((acc, item) => {
            return acc + item.price * item.amount;
        }, initialState.total);
    }, [ cartState.items, initialState.total ]);
    
    const { result } = usePurchaseVerification(total, selectedWallet?.id || '', cartState.items)
    
    const mergedCartState = {
        ...cartState,
        total,
        purchaseVerification: result
    }
    
    return (
        <ContextProvider value={{ cartState: mergedCartState, dispatch }}>
            { children }
        </ContextProvider>
    )
}

export { cartContext };
export default CartContextProvider;