import { FC, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../states/hooks/useAuth/useAuth';
import { Button } from '../../basics';
import Popover from '../popover/Popover';

type Props = {
    show: boolean;
};

const UserWalletOnboardingPopover: FC<Props> = ({ show }) => {
    const [ active, setActive ] = useState(show);
    
    return (
        <Popover active={ active } onClose={() => setActive(false)} persistent>
            <div className="mb-6">
                <h3 className="popover__title">Welkom!</h3>
                <h4 className="popover__subtitle">Voeg je eerste wallet toe</h4>
            </div>
            <p className="text--secondary">Om de app te kunnen gebruiken maak je eerst een wallet aan. Vraag daarna aan stamleiding om krediet toe te voegen.</p>
            <Link to="/user/wallets" className="block mt-6">
                <Button theme="main" icon="arrow-right">Naar wallets</Button>
            </Link>
        </Popover>
    )
}

export default UserWalletOnboardingPopover;